<template>
  <div style="box-shadow: rgba(0, 0, 0, 0.3) 0px 20px 22px 0px;">
    <button
      @click="$emit('close')"
      v-if="showCloseButton"
      type="button"
      data-dismiss="modal"
      aria-label="Close"
      class="close"
      style="width: 30px; height: 30px; border-radius: 30px; font-size: 30px; background: black;"
    >
      <span aria-hidden="true">×</span>
    </button>

    <div>
      <div class="xs-flex text-glitch-fix" v-if="!hasDisposition">
        <div
          data-item-id="localisation-delivery"
          data-item-type="tool"
          data-item-position="1"
          data-synth="form-delivery"
          data-type="DELIVERY"
          class="order-type-button xs-flex-center xs-flex-auto xs-min-width-0 xs-self-end xs-p-15 bold rounded-top no-outline xs-nowrap text-glitch-fix xs-width-50 xs-fill-white mr2 t16"
          v-bind:class="isDelivery"
          role="button"
          tabindex="0"
          v-on:click="clickActiveDelivery"
        >
          <i class="icon-delivery xs-mr-5"></i>
          <span data-synth="form-delivery-tab-name">{{ $t('delivery') }}</span>
        </div>
        <div
          data-item-id="localisation-takeaway"
          data-item-type="tool"
          data-item-position="2"
          data-synth="form-collection"
          data-type="CARRYOUT"
          class="order-type-button xs-flex-center xs-flex-auto xs-min-width-0 xs-self-end xs-p-15 bold rounded-top no-outline xs-nowrap text-glitch-fix xs-width-50 xs-fill-white t16"
          v-bind:class="isCollection"
          role="button"
          tabindex="0"
          v-on:click="clickActiveCollection"
        >
          <i class="icon-collection xs-mr-5"></i>
          <span data-synth="form-collection-tab-name">{{
            $t('collection')
          }}</span>
        </div>
      </div>
      <div
        class="localisation xs-fill-fcfcfc rounded-bottom xs-fill-white p15"
        v-if="!hasDisposition"
      >
        <div class="contactless-info">
          <h2 class="contactless-header">
            {{
              orderType === this.$const.DELIVERY
                ? $t('contactless_delivery_header')
                : $t('contactless_pickup_header')
            }}
          </h2>
          <p class="contactless-description">
            {{
              orderType === this.$const.DELIVERY
                ? $t('contactless_delivery_description')
                : $t('contactless_pickup_description')
            }}
            
          </p>
        </div>
        <div v-if="$auth.check()">
          <div
            v-show="
              orderType === $const.DELIVERY && customer.list_address.length > 0
            "
          >
            <div
              class="xs-text-center heading-hr t16 xs-p-15"
              style="padding-bottom: 5px !important;"
            >
              {{ $t('home.saved_address') }}
            </div>
            <div
              class="xs-pb-15 xs-fill-yellow xs-text-black xs-p-15 xs-mb-10 arrow-bottom-yellow typo-l6b"
              v-if="
                $route.query.isShowWarning && $route.query.isShowWarning === 1
              "
            >
              <span>
                {{
                  $t('home.' + orderType.toLowerCase() + '.popup_select_local')
                }}
              </span>
            </div>
            <div class="pl-20 pr-20">
              <v-select
                :multiple="false"
                :options="customer.list_address"
                class="select-localization"
                @input="setSelectedAddress"
              ></v-select>
            </div>
          </div>
          <div
            v-show="
              orderType === $const.CARRYOUT && customer.list_store.length > 0
            "
          >
            <div
              class="xs-text-center heading-hr t16 xs-p-15"
              style="padding-bottom: 5px !important;"
            >
              {{ $t('home.saved_store') }}
            </div>
            <div
              class="xs-pb-15 xs-fill-yellow xs-text-black xs-p-15 xs-mb-10 arrow-bottom-yellow typo-l6b"
              v-if="
                $route.query.isShowWarning && $route.query.isShowWarning === 1
              "
            >
              <span>
                {{
                  $t('home.' + orderType.toLowerCase() + '.popup_select_local')
                }}
              </span>
            </div>
            <div class="pl-20 pr-20">
              <v-select
                :multiple="false"
                :options="customer.list_store"
                class="select-localization"
                @input="setSelectedStore"
              ></v-select>
            </div>
          </div>
        </div>
        <div
          class="xs-text-center heading-hr t16 xs-p-15"
          style="padding-bottom: 5px !important; display: none !important;"
          v-if="orderType === $const.DELIVERY"
        >
          {{ $t('home.memo_where') }}
        </div>
        <div
          class="xs-text-center heading-hr t16 xs-p-15"
          style="padding-bottom: 5px !important; display: none !important;"
          v-else
        >
          {{ $t('home.memo_find') }}
        </div>
        <div
          class="xs-pb-15 xs-fill-yellow xs-text-black xs-p-15 xs-mb-10 arrow-bottom-yellow typo-l6b"
          v-if="
            $route.query.isShowWarning &&
              $route.query.isShowWarning === 1 &&
              ((customer.list_address.length <= 0 &&
                orderType == $const.DELIVERY) ||
                (customer.list_store.length <= 0 &&
                  orderType == $const.CARRYOUT))
          "
        >
          <span>{{
            $t('home.' + orderType.toLowerCase() + '.popup_select_local')
          }}</span>
        </div>
        <div class="clearfix"></div>
        <div v-if="messagePopup.length > 0" class="pt-10">
          <p class="pl-10 pr-10 flex1 display-flex items-center">
            <span class="xs-text-red" data-synth="message-popup">{{
              messagePopup
            }}</span>
          </p>
        </div>
        <div class="clearfix"></div>
        <div
          v-if="
            outletList.length <= 0 &&
              addressList.length <= 0 &&
              message.length > 0
          "
          class="pt-10"
        >
          <p class="pl-10 pr-10 flex1 display-flex items-center">
            <span class="xs-text-red">{{ message }}</span>
          </p>
        </div>
        <div class="clearfix"></div>
        <em class="text-red" v-if="$route.query.focusLocalization == true">
          {{ $t('home.must_localize') }}
        </em>
        <em class="text-red" v-if="$route.query.checkDeals == true">
          {{ $t('localization_deals') }}
        </em>

        <div>
          <div class="xs-flex xs-z-300 py-10">
            <div
              class="xs-flex xs-items-end xs-border xs-border-gray-light xs-border-1 rounded xs-relative xs-z-300 xs-fill-white xs-items-center width-full ml-5"
            >
              <div class="xs-flex-1">
                <input
                  class="search search--hut input xs-border-none xs-py-10 xs-px-10 text-14 address-autocomplete"
                  id="address-autocomplete"
                  data-synth="address-input"
                  name="address"
                  autocomplete="nope"
                  autocorrect="off"
                  autocapitalize="off"
                  spellcheck="false"
                  v-bind:placeholder="
                    orderType == $const.DELIVERY
                      ? $t('localization.placehover_search_by_address_postcode')
                      : $t('localization.placehover_search_by_collection')
                  "
                  value
                  type="text"
                  v-model="searchAutoComplete"
                  data-hj-whitelist
                  @keyup="changeAutoComplete"
                />
              </div>
              <div class="h-full">
                <a
                  href="javascript: void(0)"
                  data-item-id="localisation-submit"
                  data-item-type="tool"
                  data-item-position="3"
                  data-synth="localisation-submit"
                  :disabled="
                    !searchAutoCompleteComponents ||
                      !searchAutoCompleteComponents.formatted_address ||
                      loading ||
                      !searchAutoComplete
                  "
                  class="button button--primary flex-1 h-full rounded-l-none"
                  style="color: white;"
                  @click="validateAddress(mapOpen)"
                >
                  <span class="flex flex-col t14 hidden-sm hidden-xs">{{
                    $t('button_order_now')
                  }}</span>
                  <i
                    class="fa fa-search t24 hidden-lg hidden-md"
                    aria-hidden="true"
                  ></i>
                </a>
              </div>
            </div>
          </div>
          <div style="height: 30px;" class="pl-10 pr-10">
            <button
              data-item-id="localisation-auto"
              data-item-type="tool"
              data-item-position="4"
              class="trigger-location use-current-location flex xs-items-center pull-left"
              v-on:click="useMyLocation"
              style="color: #4a90e2"
            >
              <i class="icon-geo mr-10"></i>
              {{ $t('using_my_local') }}
            </button>
            <span
              v-if="isGoogleAutocompleteSearching"
              style="color: #4a90e2"
              v-html="$t('finding_your_neighborhood')"
            ></span>
          </div>
          <div class="pl-10 pr-10">
            <div
              v-for="(prediction, index) in predictedPlaces"
              :key="index"
              class="suggestion-item xs-p-10"
              data-synth="suggestion-item"
            >
              <div v-on:click="selectSuggestedAddress(prediction)">
                <span class="h4 bold">
                  {{ prediction.structured_formatting.main_text }}
                </span>
                <div>{{ prediction.structured_formatting.secondary_text }}</div>
              </div>
            </div>
          </div>
          <div class="clearfix"></div>
        </div>
        <div class="clearfix"></div>
        <div class="contain-outlet" v-if="mode == 'free'">
          <ul
            class="list-outlets"
            v-if="addressList.length > 0 && searchAutoComplete.length > 0"
          >
            <li
              v-for="(outlet, index) in addressList"
              :key="index"
              class="outlet-item inactive text-black"
              data-synth="outlet-item"
              @click="clickChosenAddress(outlet)"
            >
              {{ outlet }}
              <h4>
                <span class="text-capitalize">
                  <i class="fa fa-map-marker"></i>
                  {{ convertString(outlet.address) }}
                  <span class="fw400 t13" style="color: #404046">{{
                    outlet.custom
                  }}</span>
                </span>
              </h4>
            </li>
          </ul>
        </div>
        <div class="clearfix"></div>
        <div class="contain-outlet" v-if="mode == 'location'">
          <ul
            class="list-outlets hidden"
            v-if="outletList.length > 0 && searchAutoComplete.length > 0"
          >
            <li
              v-for="(outlet, index) in outletList"
              :key="index"
              class="outlet-item inactive text-black"
              data-synth="outlet-item"
              v-bind:class="{
                highlight: index === highlight,
                grayedout: loading
              }"
              @click="clickChosenOutlet(outlet)"
            >
              <h4>
                {{ index + 1 }}.
                <span class="text-capitalize">{{ outlet.name }}</span>
                <span class="t12"
                  >( {{ formatNum2Fix(outlet.distance) }} km )</span
                >
              </h4>
              <p>{{ outlet.address }}</p>
            </li>
          </ul>
        </div>
      </div>
      <div class="clearfix"></div>
    </div>
    <div
      class="localisation xs-fill-fcfcfc rounded-bottom xs-fill-white"
      style="border-radius: 4px"
      v-if="hasDisposition"
    >
      <div class="p-10">
        <div v-if="$i18n.locale === $const.LANG_ES">
          <p>
            <span class="t14">
              {{
                $disposition.getByKey(this, 'orderType', '') === $const.DELIVERY
                  ? $t('delivery_to')
                  : $t('take_away')
              }}
            </span>
            <b class="border-b">
              {{
                getDeliveryInfo(this).addressInfo ||
                  getDeliveryInfo(this).outletAddress
              }}
            </b>
          </p>
        </div>
        <div v-else>
          <p>
            <span class="t14">
              {{
                $disposition.getByKey(this, 'orderType', '') === $const.DELIVERY
                  ? $t('delivery_to')
                  : $t('take_away')
              }}
            </span>
            <span class="border-b">
              {{
                getDeliveryInfo(this).addressInfo ||
                  getDeliveryInfo(this).outletAddress
              }}
            </span>
          </p>
        </div>

        <div class="p-10 pb-0">
          <button
            class="btn btn-default w-full bg"
            style="min-height: 42px; background-color: #cccccc45"
            data-synth="reset-disposition"
            @click="resetDisposition"
          >
            {{ $t('button_change') }}
          </button>
        </div>
        <div class="p-10">
          <button
            v-on:click="viewMenu"
            data-synth="view-all-deals"
            class="btn btn-success w-full"
            style="min-height: 42px"
          >
            {{ $t('home.button_see_all_deal') }}
          </button>

          <!--
               <button v-on:click="$root.$emit('onProcessChangeCategory')" class="btn btn-success w-full" style="min-height: 42px"> {{$t('home.button_view_all_deal')}}</button>
          -->
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import ASAPPopup from '../popup/OrderASAP.vue'
import ModalCarryout from '../popup/ModalCarryout.vue'
import ModalDelivery from '../popup/ModalDelivery.vue'
import ModalLocalizationSwitchConfirmation from '../popup/ModalLocalizationSwitchConfirmation.vue'
import ModalMap from '../popup/ModalMaps.vue'
import ModalDrawMap from '../popup/ModalDrawMaps'
import vSelect from 'vue-select'
import * as action from '../../store/actions'
import { mapActions, mapState } from 'vuex'
import _ from 'lodash'

let active = 'xs-fill-fcfcfc xs-text-red xs-relative xs-z-200 xs-py-15'
let inactive = 'xs-fill-gray-lightest xs-text-gray-medium xs-grayscale xs-py-10'
var keApi = ''
var region = ''
var language = ''
export default {
  name: 'localization',
  props: ['showCloseButton', 'isReInitHeader'],
  mounted() {
    var vm = this
    window.infowindowClick = function(index) {
      const extNumberElement = document.getElementById('exterior-number')
      const addr = vm.parseAddressComponents(
        vm.searchAutoCompleteComponents.address_components,
        vm.searchAutoCompleteComponents
      )
      if (extNumberElement && extNumberElement.value) {
        if (extNumberElement.value) {
          vm.$session.set('exteriorNumber', extNumberElement.value)
        } else {
          vm.$session.remove('exteriorNumber')
        }
      }
      if (vm.orderType === vm.$const.DELIVERY) {
        if (!vm.isValidAddress(addr)) {
          vm.$notify({
            type: 'Error',
            title: vm.$t('localization.modal.error.invalid_address')
          })
          return
        }
        if (!addr.number && !extNumberElement.value) {
          vm.$notify({
            type: 'Error',
            title: vm.$t('localization.modal.error.exterior_number_required')
          })
          return
        }
      }
      
      vm.infowindowClick(index, vm.searchAutoCompleteComponents)
    }
    this.tracklocalization()
    this.initGeocoder()
    this.$disposition.init(this)
    vm.$root.$emit('dragged', false)
    // vm.$root.$emit('numOutlets', 0)
    this.$root.$on('onChangeOutlet', index => {
      vm.onChangeOutlet(index)
    })
    this.$root.$on('onclickChosenOutlet', outlet => {
      vm.clickChosenOutlet(outlet)
    })
    this.$root.$on('onTriggerOrder', () => {
      vm.triggerOrder()
    })
    this.$root.$on('triggerDeliverOrder', () => {
      vm.triggerDelivery()
    })
    this.$root.$on('activeCollectionMap', () => {
      this.clickActiveCollection()
      this.searchLocation = true
      this.searchAutoComplete = this.addressSelected
    })
    this.$root.$on('onCheckValidOutlet', data => {
      vm.checkValidOutlet()
    })
    this.$root.$on(
      'drawMap',
      (newCenter = undefined, placeDetails = undefined) => {
        setTimeout(() => {
          vm.clearOutlet()
          vm.findOutlet(newCenter)
          vm.searchAutoComplete = placeDetails.formatted_address
          vm.searchAutoCompleteComponents = placeDetails
        }, 500)
      }
    )
    this.$root.$on('switchToCarryout', () => {
      vm.$modal.hide('dialog')
      vm.isDelivery = inactive
      vm.isCollection = active
      vm.disposition.orderType = vm.$const.CARRYOUT
      vm.orderType = vm.$const.CARRYOUT
      vm.searchByLocation(
        vm.locationCenter.lat,
        vm.locationCenter.lng
      )
      let loadingCheck = setInterval(() => {
        if (!vm.loading) {
          vm.validateAddress(vm.mapOpen)
          clearInterval(loadingCheck)
        }
      }, 2000)
      
    })

    if (this.$auth.check()) {
      this.initCustomerList()
    }

    // if (this.$session.get('orderType')) {
    //   this.orderType = this.$session.get('orderType')

    //   if (this.orderType === this.$const.DELIVERY) {
    //     this.isDelivery = active
    //     this.isCollection = inactive
    //   } else {
    //     this.isDelivery = inactive
    //     this.isCollection = active
    //   }
    // }
    // Default to delivery tab as always
    this.isDelivery = active
    this.isCollection = inactive

    // boyd
    if (this.$route.query.store_code) {
      this.localisationDirectlyTakeaway(this.$route.query.store_code)
    }

    // here maps
    if (window.H) {
      this.herePlatform = new window.H.service.Platform({
        apikey: process.env.VUE_APP_HERE_API_KEY
      })
      this.hereSearchService = this.herePlatform.getSearchService();
    }

    if (this.hasDisposition) {
      this.eventTrackingAutoLocalised()
    }
  },
  beforeDestroy() {
    this.$root.$off('onChangeOutlet')
    this.$root.$off('onclickChosenOutlet')
    this.$root.$off('onTriggerOrder')
    this.$root.$off('activeCollectionMap')
    this.$root.$off('onCheckValidOutlet')
    this.$root.$off('drawMap')
  },
  created: function() {
    keApi = process.env.VUE_APP_GOOGLE_MAP_API_KEY
    region = this.$const.COUNTRY_CODE
    language = this.$session.get('lang') || process.env.VUE_APP_DEFAULT_LANGUAGE
    this.loadScriptMap()
  },
  computed: mapState(['loading']),
  watch: {
    loading: function(newValue, oldValue) {
      const mapSelectionButtonElement = document.getElementsByClassName(
        'maps-infowindow'
      )[0]
      const confirmDeliveryButtonElement = document.getElementsByClassName(
        'confirm-delivery'
      )[0]
      const saveDateTimeButtonElement = document.getElementsByClassName(
        'save-date-time'
      )[0]
      if (newValue) {
        if (mapSelectionButtonElement) {
          mapSelectionButtonElement.setAttribute('disabled', 'disabled')
          mapSelectionButtonElement.innerText = this.$t('one_moment_dot3')
        }
        if (saveDateTimeButtonElement) {
          saveDateTimeButtonElement.setAttribute('disabled', 'disabled')
        }
        if (confirmDeliveryButtonElement) {
          confirmDeliveryButtonElement.setAttribute('disabled', 'disabled')
          const spanElement = confirmDeliveryButtonElement.getElementsByTagName(
            'span'
          )[0]
          if (spanElement) {
            spanElement.innerText = this.$t('one_moment_dot3')
          }
        }
      } else {
        if (mapSelectionButtonElement) {
          mapSelectionButtonElement.removeAttribute('disabled')
          mapSelectionButtonElement.innerText = this.$t('continuar')
        }
        if (saveDateTimeButtonElement) {
          saveDateTimeButtonElement.removeAttribute('disabled')
        }
        if (confirmDeliveryButtonElement) {
          confirmDeliveryButtonElement.removeAttribute('disabled')
          const spanElement = confirmDeliveryButtonElement.getElementsByTagName(
            'span'
          )[0]
          if (spanElement) {
            spanElement.innerText = this.$t('continuar')
          }
        }
      }
    },
    searchAutoComplete: function(newValue, oldValue) {
      this.clearMessage()
      this.errorPostalCode = ''
      const vm = this
      // if (!this.searchLocation) {
      if (!this.searchAutoComplete) {
        this.clearOutlet()
      }
      // Ignore when a suggested location's address is set
      if (
        this.searchAutoCompleteComponents &&
        this.searchAutoCompleteComponents.formatted_address == newValue
      ) {
        return
      }
      if (this.searchTimeout) {
        clearTimeout(this.searchTimeout)
      }
      this.searchTimeout = setTimeout(() => {
        let autoCompleteService = new google.maps.places.AutocompleteService()
        autoCompleteService.getPlacePredictions(
          {
            input: newValue,
            offset: newValue.length,
            AUTO_COMPLETE_PATTERN: vm.AUTO_COMPLETE_PATTERN
          },
          function listentoresult(list, status) {
            vm.predictedPlaces = list
            if (!list) {
              // GOOGLE Geocoding
              // vm.getAddressGeocodingInfos(newValue)

              // Here Geocoding
              vm.searchAddressFromHereGeolocation(newValue, (list) => {
                vm.predictedPlaces = list
              })
            }
          }
        )
      }, 300)
      // }
    }
  },
  data() {
    return {
      sendLocalisedStart: false,
      deliveryInfo: this.getDeliveryInfo(this),
      customer: {
        list_address: [],
        list_store: []
      },
      hasDisposition: this.checkPosition(this),
      errorPostalCode: '',
      errorNumber: '',
      highlight: null,
      locationCenter: { lat: 0, lng: 0 },
      searchLocation: false,
      searchTimeout: null,
      isDelivery: active,
      isCollection: inactive,
      orderType: this.$const.DELIVERY,
      isShow: this.checkPosition(this),
      searchAutoComplete: '',
      predictedPlaces: [],
      searchAutoCompleteComponents: {},
      outletList: [],
      addressList: [],
      mode: '',
      loadingFreeText: false,
      loadingZip: false,
      invalidZipCode: false,
      result: {},
      message: '',
      messagePopup: '',
      geocoder: {},
      map: {},
      marker: {},
      markers: [],
      lastMarker: undefined,
      addressAutoComplete: false,
      addressSelected: '',
      deliveryAddress: '',
      mapOpen: false,
      locationObject: {
        lat: 0,
        lng: 0
      },
      disposition: {},
      isGoogleAutocompleteSearching: false,
      AUTO_COMPLETE_PATTERN: {
        types: ['address'],
        componentRestrictions: { country: this.$const.COUNTRY_CODE }
      },
      logging: {
        market: 'MX',
        time: new Date(),
        sessionId: 1,
        address: '',
        googleRequest: '',
        googleResponse: '',
        storeId: 0,
        useMyLocation: false,
        pinMoved: false
      },
      herePlatform: undefined,
      hereSearchService: undefined,
      mapCalled: 0
    }
  },
  methods: {
    ...mapActions({
      setDisposition: action.SET_DISPOSITION
    }),
    changeAutoComplete () {
      if (!this.sendLocalisedStart) {
        this.sendLocalisedStart = true
        this.eventTrackingLocalisedStart()
      }
    },
    searchAddressFromHereGeolocation(address, outFunc = undefined) {
      // const vm = this;
      if (this.hereSearchService.geocode)
        this.hereSearchService.geocode({
          q: address
        }, (result) => {
          // Add a marker for each location found
          if (outFunc) {
            outFunc(result.items.map((item) => {
              return {
                from: 'here',
                description: item.title,
                matched_substrings: [],
                place_id: item.id,
                reference: item.id,
                structured_formatting: {
                  main_text: item.title,
                  secondary_text: ' '
                },
                formatted_address: item.title,
                address_components: item.address,
                geometry: {
                  location: {
                    lat: () => item.position.lat,
                    lng: () => item.position.lng
                  }
                },
                terms: [],
                types: [item.resultType, item.houseNumberType]
              }
            }))
          }
        }, () => { })
    },
    viewMenu() {
      this.$store.commit('setCategoryUuid', null)
      this.redirectToPage(this.$const.VIEW_MENU_DEFAULT)
    },
    resolveAddress(address_component) {
      const formatted = address_component.reduce(
        (accumulator, currentValue) => {
          const componentType = currentValue.types[0]
          if (!accumulator.hasOwnProperty(componentType)) {
            accumulator[componentType] = currentValue.short_name
          } else if (currentValue.types.length >= 2) {
            accumulator[currentValue.types[1]] = currentValue.short_name
          }
          return accumulator
        },
        {}
      )
      return formatted
    },
    formatAddress(street, number, neighborhood, city, state, cep) {
      return `${street} ${number} ${neighborhood}, ${city} ${state}`
    },
    localisationDirectlyTakeaway (code) {
      var vm = this
      vm.callEventBus(vm.$const.EVENT_BUS.LOADING, true)
      this.axios.apiOutlet.getOutLetByCode({code: code}, function (response) {
        vm.$session.remove('cart')
        vm.$session.remove('disposition')
        vm.$root.$emit('cartUpdate')
        vm.hasDisposition = false
        vm.result = {}
        let outletInfo = response.data.data.item
        vm.result.outletId = outletInfo.id
        vm.result.outletCode = outletInfo.code
        vm.isDelivery = inactive
        vm.isCollection = active
        vm.result.takeaWayLess = 1
        vm.result.orderType = vm.$const.CARRYOUT
        vm.orderType = vm.$const.CARRYOUT
        vm.checkValidOutlet()
        vm.callEventBus(vm.$const.EVENT_BUS.LOADING, false)
      },function (error) {
        vm.callEventBus(vm.$const.EVENT_BUS.LOADING, false)
      })
    },
    tracklocalization() {
      let layer = {
        assets: {
          tools: [
            {
              id: 'localisation-delivery',
              type: 'storefinder',
              name: 'delivery-tab',
              attributes: {
                function: 'show-delivery-tab'
              }
            },
            {
              id: 'localisation-takeaway',
              type: 'storefinder',
              name: 'takeaway-tab',
              attributes: {
                function: 'show-takeaway-tab'
              }
            },
            {
              id: 'localisation-submit',
              type: 'storefinder',
              name: 'submit-button',
              attributes: {
                function: 'submit-location-search'
              }
            },
            {
              id: 'localisation-auto',
              type: 'storefinder',
              name: 'auto-locate-button',
              attributes: {
                function: 'auto-locate-user'
              }
            }
          ]
        }
      }
      this.eventTrackingModule(layer)
    },
    initCustomerList() {
      var vm = this
      this.axios.apiCustomer.getProfile(response => {
        let data = []
        for (let i in response.data.data.item.locations) {
          data.push({
            label: response.data.data.item.locations[i].name || response.data.data.item.locations[i].display_address,
            code: response.data.data.item.locations[i]
          })
        }
        let dataStore = []
        for (let j in response.data.data.item.outlets) {
          dataStore.push({
            label: response.data.data.item.outlets[j].outlet_name,
            code: response.data.data.item.outlets[j]
          })
        }
        vm.customer.list_address = data
        vm.customer.list_store = dataStore
      })
    },
    setSelectedStore(value) {
      if (!value) {
        return
      }
      var vm = this
      vm.result = {}
      this.axios.apiOutlet.getOutLetByCode(
        { code: value.code.outlet_code },
        response => {
          let data = response.data.data.item
          if (data) {
            vm.clickChosenOutlet(data)
          } else {
            vm.$notify({
              type: 'warn',
              title: vm.$t('warning_store_invalid')
            })
          }
        }
      )
    },
    setSelectedAddress(value) {
      if (!value) {
        return
      }
      this.searchAutoComplete = value.code ? value.code.display_address : ''
      // var vm = this
      // vm.result = {}
      // vm.result.locationName = value.code.location
      // vm.result.addressCode = value.code.location_code
      // vm.result.orderType = this.$const.DELIVERY
      // vm.result.houseNo = value.code.house_no
      // vm.result.landmark = value.code.landmark
      // vm.result.unitNo = value.code.unit_no

      // this.axios.apiOutlet.getAddressByAddressCode(
      //   { address_code: value.code.location_code },
      //   response => {
      //     let data = response.data.data.items
      //     if (data.length > 0) {
      //       vm.result.prefectureName = data[0].level1
      //       vm.result.cityName = data[0].level2
      //       vm.result.villeageName = data[0].level3
      //       vm.result.st = data[0].level4
      //       vm.result.outletId = data[0].outlet_id
      //       data.outletCode = data[0].outlet_code
      //       let address = [
      //         vm.convertString(vm.result.prefectureName),
      //         vm.convertString(vm.result.cityName),
      //         vm.convertString(vm.result.villeageName),
      //         vm.convertString(vm.result.st)
      //       ]
      //       vm.result.outletAddress = address.join(', ')

      //       vm.checkValidOutlet()
      //     } else {
      //       vm.$notify({
      //         type: 'warn',
      //         title: vm.$t('warning_address_invalid')
      //       })
      //     }
      //   }
      // )
    },
    resetDisposition() {
      var vm = this
      vm.$modal.show('dialog', {
        text: vm.$t('confirm_change_disposition'),
        buttons: [
          {
            title: vm.$t('button_yes'),
            handler: () => {
              vm.$modal.hide('dialog')
              this.$session.remove('cart')
              this.$session.remove('disposition')
              this.$session.remove('exteriorNumber')
              vm.setDisposition({})
              this.$root.$emit('cartUpdate')
              this.hasDisposition = false
              if (this.isReInitHeader) {
                this.$root.$emit('collectionTimeUpdate')
              }

              vm.$root.$emit('onReInitHeader')
            }
          },
          {
            title: vm.$t('button_close')
          }
        ]
      })
    },
    clearText() {
      // this.searchLocation = false
      this.searchAutoComplete = ''
      this.searchAutoCompleteComponents = {}
      this.clearOutlet()
    },
    findOutlet(newCenter) {
      var vm = this

      if (vm.orderType === vm.$const.CARRYOUT) {
        let data = {
          location: newCenter.lng + ',' + newCenter.lat,
          order_type: vm.orderType.charAt(0),
          limit: 4
        }
        vm.axios.apiOutlet.checkLocation(data, function(response) {
          if (response.data.status) {
            if (response.data.data && response.data.data.items.length > 0) {
              vm.$root.$emit('numOutlets', response.data.data.items.length)
              for (const store of response.data.data.items) {
                vm.outletList.push(store)
              }
              vm.drawMaps(false, newCenter)
            } else {
              vm.$root.$emit('numOutlets', 0)
            }
          }
        })
      } else {
        vm.drawMaps(false, newCenter)
      }
    },
    clearOutlet() {
      this.outletList = []
      this.addressList = []
      this.sendLocalisedStart = false
    },
    clearMessage() {
      this.messagePopup = ''
      this.message = ''
    },
    inputSearchFocus() {
      if (this.searchAutoComplete) {
        if (this.orderType === this.$const.DELIVERY) {
          this.searchByAddress()
        }
      }
    },
    showHideMap(flag) {
      if (!window.google) return
      this.mapOpen = !flag
      // FIXME: Some logic to add store when show-hide-map
      // if (
      //   this.outletList.filter(x => x.outletId == this.disposition.outletId)
      //     .length == 0
      // ) {
      //   if (this.disposition.orderType === this.orderType) {
      //     this.outletList.push({
      //       ...this.disposition,
      //       name: this.disposition.outletName,
      //       address: this.disposition.outletAddress,
      //       distance: 0
      //     })
      //   }
      // }
      if (this.mapOpen) {
        this.$modal.show(
          ModalMap,
          { outletList: this.outletList, dragged: false },
          { height: 'auto' },
          {
            opened: this.beforeOpen,
            'before-close': this.beforeClose
          }
        )
      } else {
        this.$root.$emit('onCloseMap')
      }
    },
    showDrawMap(lat, lng) {
      this.locationObject.lat = lat
      this.locationObject.lng = lng
      this.$modal.show(
        ModalDrawMap,
        { lat: lat, log: lng },
        { height: 'auto', classes: 'draws-modal v--modal' },
        {
          opened: this.beforeOpenDraw,
          'before-close': this.beforeCloseDraw
        }
      )
    },
    /**
     *  Init google map
     * */
    initMap() {
      var mapStyle = [
        {
          featureType: 'poi',
          elementType: 'labels',
          stylers: [{ visibility: 'off' }]
        }
      ]
      this.map = new google.maps.Map(
        document.getElementById('mapLocalization'),
        {
          center: { lat: -34.397, lng: 150.644 },
          maxZoom: 18,
          minZoom: 10,
          streetViewControl: false,
          rotateControl: false,
          fullscreenControl: false,
          mapTypeControl: false,
          styles: mapStyle,
          gestureHandling: 'greedy'
        }
      )
    },
    getAddressGeocodingInfos(query) {
      const vm = this
      this.axios.queryGoogleGeocoding(
        query,
        function(response) {
          vm.predictedPlaces = response
            // .filter(item => {
            //   const address = vm.parseAddressComponents(item.address_components)
            //   return vm.isValidAddress(address)
            // })
            .map(item => ({
              structured_formatting: {
                main_text: item.formatted_address,
                secondary_text: ' '
              },
              origin: item,
              from: 'geolocator'
            }))
        },
        function(data) {
          vm.predictedPlaces = []
        }
      )
    },
    selectSuggestedAddress(place) {
      const vm = this
      if (place.from == 'geolocator') {
        // Google GEOCODING implementation
        vm.predictedPlaces = []
        vm.searchAutoComplete = place.origin.formatted_address
        vm.searchAutoCompleteComponents = place.origin
        if (vm.orderType === vm.$const.CARRYOUT) {
          vm.prepareToLocalize(place.origin)
        }
      } else if (place.from == 'here') {
        // HERE GEOCODING implementation
        vm.predictedPlaces = []
        vm.searchAutoComplete = place.formatted_address
        vm.searchAutoCompleteComponents = place.address_components
        vm.prepareToLocalize(place)
      } else {
        // Google GEOCODING implementation
        const placesService = new google.maps.places.PlacesService(
          document.getElementById('address-autocomplete')
        )
        placesService.getDetails(
          { reference: place.reference },
          function detailsresult(detailsResult, placesServiceStatus) {
            vm.predictedPlaces = []
            vm.searchAutoComplete = detailsResult.formatted_address
            vm.searchAutoCompleteComponents = detailsResult
            if (vm.orderType === vm.$const.CARRYOUT) {
              vm.prepareToLocalize(detailsResult) // select the first found location
            }
          }
        )
      }
    },
    prepareToLocalize(place) {
      let vm = this
      if (place) {
        vm.outletList = [] // clear outlet list
        vm.lastMarker = undefined
        vm.searchAutoComplete = place.formatted_address
        vm.searchAutoCompleteComponents = place
        if (
          typeof place.place_id === 'undefined' &&
          typeof place.geometry === 'undefined'
        ) {
          return
        }
        let lat = place.geometry.location.lat()
        let lng = place.geometry.location.lng()

        if (vm.orderType === vm.$const.CARRYOUT) {
          vm.searchByLocation(lat, lng)
        } else {
          let myLatLng = lat + ',' + lng
          let objAddress = {
            address: 'My Location',
            latlng: myLatLng,
            lat: lat,
            lng: lng,
            place_id: place.place_id
          }
          this.locationCenter.lat = lat
          this.locationCenter.lng = lng
          vm.showHideMap(this.mapOpen)
        }
      }
    },
    /**
     * Click on Delivery tab
     * */
    clickActiveDelivery() {
      this.mapCalled = 0
      this.clearText()
      this.addressAutoComplete = false
      this.initGeocoder()
      this.isDelivery = active
      this.isCollection = inactive
      this.orderType = this.$const.DELIVERY
      this.clearMessage()
      this.addressList = []
      this.outletList = []
      this.$root.$emit('onChangeTab', this.orderType.toLowerCase())

      this.$session.set('orderType', this.$const.DELIVERY)
    },
    /**
     * Click on Collection tab
     * */
    clickActiveCollection() {
      this.mapCalled = 0
      this.clearText()
      this.addressAutoComplete = false
      this.initGeocoder()
      this.isDelivery = inactive
      this.isCollection = active
      this.orderType = this.$const.CARRYOUT
      this.clearMessage()
      this.addressList = []
      this.outletList = []
      this.$root.$emit('onChangeTab', this.orderType.toLowerCase())

      this.$session.set('orderType', this.$const.CARRYOUT)
    },
    infowindowClick(index, overriddenAddressComponents = undefined) {
      if (index < 0) {
        if (overriddenAddressComponents) {
          this.searchAutoCompleteComponents = overriddenAddressComponents
          this.locationCenter.lat = overriddenAddressComponents.geometry.location.lat()
          this.locationCenter.lng = overriddenAddressComponents.geometry.location.lng()
        }
        if (!_.isEmpty(this.searchAutoCompleteComponents)) {
          var addr = this.parseAddressComponents(
            this.searchAutoCompleteComponents.address_components,
            this.searchAutoCompleteComponents
          )
          if (
            (!addr.number ||
            !addr.street ||
            !addr.city ||
            !addr.neighborhood ||
            !addr.state ||
            !addr.country) && (addr.lat == null && addr.lng == null)
          ) {
            this.$notify({
              type: 'error',
              title: this.$t(
                'localization.validation_message.missing_delivery_address_components'
              )
            })
          } else {
            this.searchByLocation(
              this.locationCenter.lat,
              this.locationCenter.lng
            )
          }
        } else {
          this.$notify({
            type: 'error',
            title: this.$t(
              'localization.validation_message.missing_delivery_address_components'
            )
          })
        }
      } else {
        let outlet = this.outletList[index]
        this.clickChosenOutlet(outlet)
      }
    },
    onChangeOutlet(index) {
      let flag = false
      if (this.locationCenter.lat != 0 && this.locationCenter.lng != 0) {
        flag = true
        // index += 1
      }
      for (let i in this.markers) {
        if (i === 0 && flag) continue
        let url = this.markers[i].originIcon
        if (i === index) {
          url = this.markers[i].hoverIcon
        }
        // this.markers[i].setIcon({
        //   url: url,
        //   scaledSize: new google.maps.Size(40, 40)
        // })
      }
      // if (this.markers.length > 0) {
      //   this.map.panTo(this.markers[index].position)
      // }
    },
    /**
     * Using your location for show google map
     * */
    useMyLocation() {
      var vm = this
      vm.clearMessage()
      vm.searchLocation = true
      if (navigator.geolocation) {
        navigator.geolocation.getCurrentPosition(
          function(position) {
            vm.getAddressByLatLog({
              lat: parseFloat(position.coords.latitude),
              lng: parseFloat(position.coords.longitude)
            })
            if (vm.orderType === vm.$const.DELIVERY) {
              // Attempt to get current address from lat, long for delivery
              vm.geocoder.geocode(
                {
                  location: {
                    lat: parseFloat(position.coords.latitude),
                    lng: parseFloat(position.coords.longitude)
                  }
                },
                function(results, status) {
                  if (status === 'OK') {
                    const mapLocationElement = document.getElementById(
                      'mapLocation'
                    )
                    if (mapLocationElement) {
                      mapLocationElement.value =
                        results[0].formatted_address || ''
                    }
                    vm.showHideMap(vm.mapOpen)
                  }
                }
              )
            } else {
              vm.searchByLocation(
                position.coords.latitude,
                position.coords.longitude
              )
            }
            vm.eventTrackingFindMyLocation()
          },
          function(error) {
            if (error.code === error.PERMISSION_DENIED) {
              vm.messagePopup = vm.$t('message_geolocation_denied')
            }
          }
        )
      }
    },
    initGeocoder() {
      if (typeof google !== 'undefined') {
        this.geocoder = new google.maps.Geocoder()
      }
    },
    /**
     * Get lat long from address
     * */
    getAddressByLatLog(latLong) {
      var vm = this
      vm.locationCenter.lat = latLong.lat
      vm.locationCenter.lng = latLong.lng
      this.initGeocoder()
      vm.searchLocation = true
      this.geocoder.geocode({ location: latLong }, function(results, status) {
        if (status === 'OK') {
          if (results[0]) {
            vm.searchAutoComplete = results[0].formatted_address
            vm.searchAutoCompleteComponents = results[0]
            if (vm.orderType === 'DELIVERY') {
              const formattedAddress = vm.resolveAddress(
                results[0].address_components
              )
              vm.deliveryAddress = vm.formatAddress(
                formattedAddress.route,
                vm.streetNumber,
                formattedAddress.political,
                formattedAddress.administrative_area_level_2,
                formattedAddress.administrative_area_level_1,
                formattedAddress.postal_code
              )

              vm.$root.$emit('onSetMessageMap', vm.deliveryAddress)
            }
          }
        }
      })
    },
    parseAddressComponents(addressComponents, generalInfos) {
      if (!addressComponents) return undefined
      if (_.isArray(addressComponents)) {
        // Google GEOCODING Implementation
        var numberComponent = addressComponents.filter(ac =>
          ac.types.includes('street_number')
        )[0]
        var streetComponent = addressComponents.filter(ac =>
          ac.types.includes('route')
        )[0]
        var neighborhoodComponent = addressComponents.filter(
          ac =>
            ac.types.includes('sublocality_level_1') ||
            ac.types.includes('neighborhood')
        )[0]
        var cityComponent = addressComponents.filter(ac =>
          ac.types.includes('locality')
        )[0]
        var stateComponent = addressComponents.filter(ac =>
          ac.types.includes('administrative_area_level_1')
        )[0]
        var countryComponent = addressComponents.filter(ac =>
          ac.types.includes('country')
        )[0]
        var postalCodeComponent = addressComponents.filter(ac =>
          ac.types.includes('postal_code')
        )[0]
        return {
          number: numberComponent ? numberComponent.long_name : null,
          street: streetComponent ? streetComponent.long_name : null,
          neighborhood: neighborhoodComponent
            ? neighborhoodComponent.long_name
            : null,
          city: cityComponent ? cityComponent.long_name : null,
          state: stateComponent ? stateComponent.long_name : null,
          country: countryComponent ? countryComponent.long_name : null,
          zip_code: postalCodeComponent ? postalCodeComponent.long_name : null,
          lat: generalInfos ? generalInfos.geometry.location.lat() : null,
          lng: generalInfos ? generalInfos.geometry.location.lng() : null
        }
      } else {
        // HERE GEOCODING Implementation
        return {
          number: addressComponents.houseNumber,
          street: addressComponents.street,
          neighborhood: addressComponents.district,
          city: addressComponents.city,
          state: addressComponents.state,
          country: addressComponents.countryName,
          zip_code: addressComponents.postalCode,
          lat: generalInfos ? generalInfos.geometry.location.lat() : null,
          lng: generalInfos ? generalInfos.geometry.location.lng() : null
        }
      }
    },
    hasLatLong(addr) {
      return (
        addr &&
        addr.lat != null &&
          typeof addr.lat !== 'undefined' &&
          addr.lng != null &&
          typeof addr.lng !== 'undefined'
      )
    },
    isValidAddress(addr) {
      return (
        addr &&
        (
          addr.number != null &&
          typeof addr.number !== 'undefined' &&
          addr.street != null &&
          typeof addr.street !== 'undefined' &&
          addr.city != null &&
          typeof addr.city !== 'undefined' &&
          addr.neighborhood != null &&
          typeof addr.neighborhood !== 'undefined' &&
          addr.state != null &&
          typeof addr.state !== 'undefined' &&
          addr.country != null &&
          typeof addr.country !== 'undefined'
        )
      )
    },
    /**
     * Validate address
     * */
    validateAddress(mapOpen) {
      if (this.loading) {
        return
      }
      var vm = this

      if (!_.isEmpty(vm.searchAutoCompleteComponents)) {
        var addr = this.parseAddressComponents(
          vm.searchAutoCompleteComponents.address_components,
          vm.searchAutoCompleteComponents
        )

        if (addr) {
          if (this.orderType === this.$const.CARRYOUT) {
            vm.showHideMap(mapOpen)
          } else if (!vm.isValidAddress(addr) && !vm.hasLatLong(addr)) {
            vm.messagePopup = vm.$t(
              'localization.validation_message.missing_delivery_address_components'
            )
          } else {
            vm.prepareToLocalize(vm.searchAutoCompleteComponents)
          }
        } else {
          vm.messagePopup = vm.$t(
            'localization.validation_message.missing_delivery_address_components'
          )
        }
      } else {
        if (this.orderType === this.$const.DELIVERY) {
          vm.messagePopup = vm.$t(
            'localization.validation_message.missing_delivery_address_components'
          )
        } else {
          vm.messagePopup = vm.$t(
            'localization.validation_message.missing_collection_address'
          )
        }
      }
    },
    /**
     * Get lat long from address
     * */
    getLatLog() {
      this.clearMessage()
    },
    /**
     * Chosen a address
     * */
    clickChosenOutlet(outlet) {
      if (this.loading) {
        return
      } // if loading, don't do anything
      let vm = this
      this.result = {}
      this.clearMessage()
      if (outlet) {
        vm.$bus.$emit('loading', 'block')
        vm.result.lat = outlet.lat
        vm.result.long = outlet.long
        vm.result.outletAddress = outlet.address
        vm.result.orderType = vm.orderType
        vm.result.outletId = outlet.id
        vm.result.outletName = outlet.name
        vm.result.outletCode = outlet.code
        vm.result.tier = outlet.tier
        vm.checkValidOutlet()
      }
    },
    /**
     * Chosen a address
     *
     * */
    clickChosenAddress(outlet) {
      this.result = {}
      this.clearMessage()
      if (outlet) {
        if (this.orderType === this.$const.DELIVERY) {
          this.searchAutoComplete = outlet.address
          this.addressSelected = outlet.address
          if (
            outlet.storenumber &&
            outlet.storenumber.trim().length > 0 &&
            outlet.storeid &&
            outlet.storeid > 0
          ) {
            this.result.outletAddress = outlet.address
            this.result.orderType = this.$const.DELIVERY
            this.result.locationName = outlet.address
            this.result.prefectureName = outlet.prefecture_name
            this.result.cityName = outlet.city_name
            this.result.villeageName = outlet.villeage_name
            this.result.st = outlet.st
            this.result.outletId = outlet.storeid
            this.result.addressCode = outlet.address_code
            this.searchAutoCompleteComponents = this.result
            this.checkValidOutlet()
          } else {
            /**
             * handle process carryout
             * **/
            let geocoder = new google.maps.Geocoder()
            var vm = this
            vm.logging.googleRequest = JSON.stringify({
              address: this.searchAutoComplete
            })
            geocoder.geocode({ address: this.searchAutoComplete }, function(
              results,
              status
            ) {
              if (status === 'OK') {
                let lat = results[0].geometry.location.lat()
                let lng = results[0].geometry.location.lng()
                vm.searchAutoCompleteComponents = results[0]
                if (!lng || !lat) {
                  vm.$modal.show(ModalDelivery, {
                    message: false,
                    methodSearch: vm.$const.DEFAULT_CONFIG_APP,
                    hideCollectionButton: true
                  })
                  return false
                }
                let val = lng + ',' + lat
                let data = { location: val, order_type: 'C', limit: 3 }
                vm.message = ''
                vm.axios.apiOutlet.checkLocation(
                  data,
                  function(response) {
                    vm.searchLocation = false

                    vm.outletList = response.data.data.items
                    if (vm.outletList.length <= 0) {
                      vm.$modal.show(ModalDelivery, {
                        message: false,
                        methodSearch: vm.$const.DEFAULT_CONFIG_APP,
                        hideCollectionButton: true
                      })
                    } else {
                      vm.$modal.show(
                        ModalCarryout,
                        {
                          methodSearch: vm.$const.DEFAULT_CONFIG_APP,
                          outletList: vm.outletList
                        },
                        { classes: 'carryout-modal' }
                      )
                    }
                  },
                  function(error) {
                    // vm.searchLocation = false
                    console.log(`error: ${error}`)
                    vm.messagePopup = vm.$t('modal.delivery_message')
                  }
                )
              } else {
                vm.messagePopup = vm.$t('modal.delivery_message')
                vm.$modal.show(ModalDelivery, {
                  message: false,
                  methodSearch: vm.$const.DEFAULT_CONFIG_APP,
                  hideCollectionButton: true
                })
              }
            })
          }
        } else {
          this.searchLocation = true
          this.searchAutoComplete = outlet.address
          this.searchAutoCompleteComponents = {} // FIXME
          this.getLatLog()
        }
      }
    },
    /**
     * Get PRB Read Cross-Streets response
     * */
    checkPRBResponse(orderType, outletCode, callback) {
      if (orderType === this.$const.DELIVERY) {
        if (
          this.searchAutoCompleteComponents &&
          this.searchAutoCompleteComponents.address_components
        ) {
          const deliveryAddress = this.parseAddressComponents(
            this.searchAutoCompleteComponents.address_components,
            this.searchAutoCompleteComponents
          )
          this.axios.apiOutlet.checkPRBOutletCrossStreets(
            {
              streetName: deliveryAddress.street,
              outletCode: outletCode,
              streetNumber: deliveryAddress.number,
              neighborhoodName: deliveryAddress.neighborhood,
              cityName: deliveryAddress.city,
              stateName: deliveryAddress.state,
              postalCode: deliveryAddress.zip_code,
              latitude: this.searchAutoCompleteComponents.geometry.location.lat(),
              longitude: this.searchAutoCompleteComponents.geometry.location.lng()
            },
            function(prbCrossStreetsResponse) {
              if (prbCrossStreetsResponse.status == 200) {
                callback({
                  for: 'DELIVERY'
                })
              } else {
                callback({
                  for: 'NOTHING'
                })
              }
            },
            function(error) {
              callback({
                for: 'NOTHING'
              })
            }
          )
        }
      } else {
        callback({
          for: 'COLLECTION'
        })
      }
    },
    /**
     * Checking outlet is available
     * */
    checkValidOutlet() {
      // check time outlet
      if (this.empty(this.result)) {
        return false
      }
      var vm = this
      let timeObject = {
        order_time: this.convertDateTimeFromTimezoneToUTC(
          this.$disposition.getByKey(this, 'timezone', ''),
          this.$disposition.getByKey(this, 'collectionTimeValue', '')
        ),
        advance_order: this.$disposition.getByKey(this, 'advanceOrder', 0) || 0
      }
      let data = {
        order_type: this.orderType.charAt(0),
        order_time: timeObject.order_time,
        advance_order: timeObject.advance_order,
        outlet_id:
          this.result.outletId || this.$disposition.getByKey(this, 'outletId')
      }
      this.axios.apiOutlet.checkOutletAvailable(
        data,
        function(response) {
          response = response.data
          if (response.status) {
            let data = vm.$disposition.get(vm)
            response = response.data.item

            vm.checkPRBResponse(vm.result.orderType, response.code, prb => {
              if (prb.for === 'DELIVERY' || prb.for === 'COLLECTION') {
                // set data
                data.prefectureName = vm.result.prefectureName || ''
                data.cityName = vm.result.cityName || ''
                data.villeageName = vm.result.villeageName || ''
                data.st = vm.result.st || ''
                data.lat = response.lat
                data.long = response.long
                data.postalCode = response.zip
                data.locationName =
                  vm.result.orderType === vm.$const.DELIVERY
                    ? vm.deliveryAddress
                    : vm.result.locationName
                data.outletAddress = response.address
                data.orderType = vm.result.orderType
                data.outletId = response.id
                data.outletName = response.name
                // data.locationName = response.address
                data.outletCode = response.code
                data.addressCode =
                  vm.result.orderType === vm.$const.DELIVERY
                    ? vm.result.addressCode
                    : ''
                data.advanceOrder =
                  vm.$disposition.getByKey(vm, 'advanceOrder', 0) || 0
                data.tier = response.tier
                data.minCart = response.min_cart
                data.houseNo = vm.result.houseNo || ''
                data.landmark = vm.result.landmark || ''
                data.unitNo = vm.result.unitNo || ''
                data.quoteTime = response.quote_time
                data.takeaWayLess = vm.result.orderType == vm.$const.CARRYOUT ? (vm.result.takeaWayLess || 0) : 0
                vm.disposition = data

                // localization logging
                vm.logging.storeId = response.code
                vm.logging.sessionId = vm.$session.id()
                vm.logging.useMyLocation = vm.searchLocation
                vm.logging.address =
                  vm.result.orderType === vm.$const.DELIVERY
                    ? vm.deliveryAddress
                    : data.locationName

                let cart = vm.$cart.get(vm)
                if (cart.uuid) {
                  vm.$modal.show('dialog', {
                    text: vm.$t('confirm.remove_cart'),
                    buttons: [
                      {
                        title: vm.$t('button_yes'),
                        handler: () => {
                          vm.proceessDispostion()
                          vm.$root.$emit('onReInitHeader')
                        }
                      },
                      {
                        title: vm.$t('button_close')
                      }
                    ]
                  })
                } else {
                  vm.proceessDispostion()
                  vm.$root.$emit('onReInitHeader')
                }
              } else {
                vm.$notify({
                  type: 'error',
                  title: vm.$t('localization.store_not_ready')
                })
              }
            })
          } else if (response.message && response.message != '') {
            vm.$notify({
              type: 'warn',
              title: response.message
            })
          }
        },
        function(error) {
          error = error.error
          let message = false
          if (error.message) {
            message = error.message
          }
          vm.eventTrackingLocalisationError({
            id: 'error-localization',
            error: 'invalid-localisation'
          })
          vm.eventTrackingFailureLocalised()
          if (error.code === vm.$const.LOCALIZATION_CODE.UNAVALIABLE) {
            message = vm.$t('message_find_outlet_empty')
            vm.eventTrackingDeadEnds(
              'Delivery DeadZone',
              'Collection Unavailable'
            )
            vm.$modal.show(ModalDelivery, {
              message: message,
              methodSearch: vm.$const.DEFAULT_CONFIG_APP,
              hideCollectionButton: false
            })
          } else if (error.code === vm.$const.LOCALIZATION_CODE.NOTOPEN) {
            message = vm.$t('localization.notopen')
            vm.eventTrackingDeadEnds(
              'Hut Closed',
              'Hut ID: ' + error.data.code
            )
            vm.$modal.show('dialog', {
              text: message,
              buttons: [
                {
                  title: vm.$t('button_close')
                }
              ]
            })
          } else {
            message = vm.$t('modal.advance_order_message')
            vm.eventTrackingDeadEnds(
              'Hut is Bouncing',
              'Hut ID: ' + error.data.code
            )
            // vm.$modal.show(ConfirmOrderAdvance, {message: message, outletId: vm.result.outletId, outletDataLocalization: vm.result, orderType: vm.orderType})
            vm.$modal.show(ASAPPopup, {
              outletId: vm.result.outletId,
              orderType: vm.orderType,
              outletDataLocalization: vm.result,
              hideASAP: 'false',
              message: message
            })
          }
        }
      )
    },
    proceessDispostion() {
      if (this.disposition.orderType === this.$const.DELIVERY) {
        const exteriorNumber = this.$session.get('exteriorNumber')
        var deliveryAddress = this.parseAddressComponents(
          this.searchAutoCompleteComponents.address_components,
          this.searchAutoCompleteComponents
        )
        const modifiedDeliveryAddress = `${deliveryAddress.street || ''} ${exteriorNumber || ''}, ${deliveryAddress.neighborhood ? `${deliveryAddress.neighborhood}, ` : ''}${deliveryAddress.city} ${deliveryAddress.zip_code}, ${deliveryAddress.country}`.trim()
        this.disposition.locationName = exteriorNumber
          ? modifiedDeliveryAddress
          : this.searchAutoCompleteComponents.formatted_address
        this.disposition.houseNo = exteriorNumber || deliveryAddress.number
        this.disposition.villeageName = deliveryAddress.street
        this.disposition.cityName = deliveryAddress.city
        this.disposition.prefectureName = deliveryAddress.state
        this.disposition.landmark = deliveryAddress.neighborhood
        this.disposition.zip_code = deliveryAddress.zip_code
        this.disposition.country = deliveryAddress.country
        this.disposition.address = exteriorNumber ? modifiedDeliveryAddress : (this.searchAutoComplete || '')
        this.disposition.delivery_lat = this.searchAutoCompleteComponents.geometry.location.lat()
        this.disposition.delivery_lng = this.searchAutoCompleteComponents.geometry.location.lng()
        this.logging.googleResponse = JSON.stringify(
          this.searchAutoCompleteComponents
        )
      }

      this.$disposition.set(this, this.disposition)
      this.setDisposition(this.disposition)

      // this.showHideMap(true)
      this.$session.remove('cart')
      this.$root.$emit('cartUpdate')

      this.eventTrackingLocalisationSuccess(this)
      if (this.isReInitHeader) {
        this.$root.$emit('onReInitHeader')
      }

      this.$root.$emit('onCloseMap')
      if (this.$route.query.callback && this.$route.query.callback.length > 0) {
        this.$router.push('/' + this.$route.query.callback)
      } else {
        // this.$root.$emit('onProcessChangeCategory')
        this.redirectToPage(this.$const.SLUG_LIST.DEAL)
      }
      this.axios.apiOutlet.loggingLocalization(this.logging)
    },
    /**
     * Search stores by free text
     * */
    searchByAddress(address) {
      var ref = this
      this.clearMessage()
      this.addressList = []
      this.outletList = []
      if (!address) {
        return false
      }
      this.mode = 'free'
      // this.locationCenter = {lat: 0, lng: 0}
      this.loadingFreeText = true
      ref.message = ''
      this.axios.apiOutlet.getNextLevelData(
        {
          address: address,
          order_type: 'D',
          limit: 10,
          client_id: 1
        },
        function(response) {
          ref.addressList = response.data.data.items
          if (ref.addressList.length <= 0) {
            ref.message = ref.$t('localization.stores_404')
          }
          ref.loadingFreeText = false
        },
        function(error) {
          console.log(`error: ${error}`)
          ref.message = ref.$t('localization.stores_404')
          ref.loadingFreeText = false
        }
      )
    },
    /**
     * show message to confirm
     */
    beforeClickChosenOutlet(outlet) {
      let vm = this
      vm.clickChosenOutlet(outlet)
    },
    /**
     * Search stores by location
     * */
    searchByLocation(lat, lng) {
      this.locationCenter.lat = lat
      this.locationCenter.lng = lng
      this.mode = 'location'
      this.outletList = []
      this.addressList = []
      if (!lng || !lat) {
        return false
      }
      let val = lng + ',' + lat
      let data = {
        location: val,
        order_type: this.orderType.charAt(0),
        limit: 4
      }
      if (this.orderType.charAt(0) === 'D') {
        data = {
          location: val,
          disposition: this.orderType.charAt(0),
          limit: 4
        }
      }
      var vm = this
      vm.message = ''
      this.axios.apiOutlet.checkLocation(
        data,
        function(response) {
          vm.searchLocation = false
          let res_data = response.data.data.items
          if (vm.orderType.charAt(0) === 'D') {
            if (res_data.length <= 0) {
              // vm.$notify({
              //   type: 'error',
              //   title: vm.$t('modal_attention_no_store_delivery')
              // })
              vm.showHideMap(vm.mapOpen)
              vm.$modal.show(ModalLocalizationSwitchConfirmation, {})
              vm.eventTrackingDeadEnds(
                'Not in Delivery Zone',
                'Address: ' + vm.searchAutoComplete
              )
            } else {
              vm.beforeClickChosenOutlet(res_data[0])
            }
            // FIXME: Keep this for future use, do not delete
            // if (res_data.length <= 0) {
            //   // call api for incase carryout
            //   vm.axios.apiOutlet.checkLocation(
            //     { limit: data.limit, location: data.location, order_type: 'C' },
            //     function(response) {
            //       let res1_data = response.data.data.items
            //       if (res1_data.length > 0) {
            //         vm.$modal.show(ModalCarryout, {
            //           methodSearch: vm.$const.DEFAULT_CONFIG_APP,
            //           outletList: res1_data
            //         })
            //       } else {
            //         vm.$modal.show(ModalDelivery, {
            //           message: false,
            //           methodSearch: vm.$const.DEFAULT_CONFIG_APP,
            //           hideCollectionButton: true
            //         })
            //       }
            //     },
            //     function(error) {
            //       vm.searchLocation = false
            //       console.log(`error: ${error}`)
            //       vm.message =
            //         vm.$t('localization.stores_404') +
            //         ' ' +
            //         vm.$t(vm.orderType.toLowerCase()).toLowerCase()
            //     }
            //   )
            // } else {
            // }
          } else {
            if (res_data.length <= 0) {
              vm.message =
                vm.$t('localization.stores_404') +
                ' ' +
                vm.$t(vm.orderType.toLowerCase()).toLowerCase()
              vm.eventTrackingDeadEnds(
                'Delivery DeadZone',
                'Collection Unavailable'
              )
            } else {
              vm.outletList = response.data.data.items
            }
          }
          // vm.searchLocation = false
          //
          // vm.outletList = response.data.data.items
          // if (vm.outletList.length <= 0) {
          //   vm.message = vm.$t('localization.not_store_carryout')
          // }
        },
        function(error) {
          vm.searchLocation = false
          console.log(`error: ${error}`)
          let text = vm.$t('localization.not_store_carryout')
          if (error.code && error.code === 'ECONNABORTED') {
            text = vm.$t('localization.location_404')
          }
          vm.message = text
        }
      )
    },
    showMapWithLocation() {
      let latlng = new google.maps.LatLng(
        this.locationObject.lat,
        this.locationObject.lng
      )
      var _marker = null
      var vm = this
      vm.$root.$emit('onSetMessageMap', vm.searchAutoComplete)
      _marker = new google.maps.Marker({
        position: latlng,
        map: this.map
      })
      this.map.setCenter(_marker.getPosition())

      google.maps.event.addListener(_marker, 'dragend', function() {
        vm.map.setCenter(_marker.getPosition())
        vm.geocodePosition(_marker.getPosition())
        let latlng = {
          lat: parseFloat(_marker.getPosition().lat()),
          lng: parseFloat(_marker.getPosition().lng())
        }
        vm.getAddressByLatLog(latlng)
        infoWindow.setContent(vm.searchAutoComplete)
      })
    },
    triggerDelivery() {
      this.$root.$emit('closeDrawMapModal')
      this.searchByLocation(this.locationObject.lat, this.locationObject.lng)
    },
    geocodePosition(pos) {
      this.$root.$emit('onSetMessageMap', this.deliveryAddress)
      var vm = this
      if (!pos.lat() || !pos.lng()) {
        vm.$root.$emit(
          'onSetMessageMap',
          vm.$t('localization.zipcode.stores_404')
        )
        return false
      }
      this.locationObject.lat = pos.lat()
      this.locationObject.lng = pos.lng()
    },
    triggerOrder() {
      var vm = this
      let val = this.locationObject.lng + ',' + this.locationObject.lat
      let data = {
        location: val,
        order_type: vm.orderType.charAt[0],
        limit: 5,
        order_time: vm.convertDateTimeToUTC(''),
        advance_order: 0
      }
      vm.message = ''
      vm.axios.apiOutlet.checkLocation(
        data,
        function(response) {
          vm.searchLocation = false

          vm.outletList = response.data.data.items
          if (vm.outletList.length <= 0) {
            vm.$root.$emit('closeDrawMapModal')
            vm.message = vm.$t('zipcode_notfound')
          } else {
            vm.$modal.show(
              ModalCarryout,
              {
                methodSearch: vm.$const.DEFAULT_CONFIG_APP,
                outletList: vm.outletList
              },
              { classes: 'carryout-modal' }
            )
          }
        },
        function(error) {
          console.log(`error: ${error}`)
          vm.$root.$emit('closeDrawMapModal')
          vm.message = vm.$t('zipcode_notfound')
        }
      )
    },

    clearMapMarkers() {
      // const markersToRemove = this.markers.filter(marker =>
      //   skipMainMarker ? marker.getTitle() !== 'estas aqui' : true
      // )
      // markersToRemove.forEach(marker => marker ? marker.setMap(null) : null)
      // this.markers = this.markers.filter(marker =>
      //   skipMainMarker ? marker.getTitle() === 'estas aqui' : false
      // )
      for (var i = 0; i < this.markers.length; i++) {
        this.markers[i].setMap(null)
        this.markers[i] = null
      }
      this.markers = []
    },

    createMarkerForOutlet(outlet, i, bounds) {
      var vm = this

      let latlng = new google.maps.LatLng(outlet.lat, outlet.long)
      let index = i + 1
      let icon = {
        url: 'https://static.phdvasia.com/mx/icons/red-logo.png',
        scaledSize: new google.maps.Size(40, 40)
      }
      let addressArray = outlet.address.split(',')
      addressArray.pop()
      let contentString =
        '<div id="content" class="p-10">' +
        '<div id="siteNotice">' +
        '</div>' +
        '<h4 id="firstHeading" class="firstHeading" style="margin: 0.5em 0;">' +
        outlet.code +
        ' - ' +
        outlet.name +
        '</h4>' +
        '<div id="bodyContent">' +
        '<p>' +
        addressArray.join(' ') +
        '</p>' +
        '<p style="margin: 0;"><button class="btn btn-success maps-infowindow t17" data-synth="confirm-collection" style="padding: 8px 20px; width:100%; font-family: SharpSemiBold Sans" onclick="infowindowClick(' +
        i +
        ')">' +
        this.$t('select') +
        '</button></p>' +
        '</div>' +
        '</div>'

      const infowindow = new google.maps.InfoWindow({
        content: contentString,
        maxWidth: 200
      })
      let marker = new google.maps.Marker({
        position: latlng,
        map: vm.map,
        draggable: false,
        animation: google.maps.Animation.DROP,
        outletIndex: i,
        outlet: outlet,
        infowindow,
        title: outlet.address,
        icon: icon,
        hoverIcon: 'https://deo515rx9pz7c.cloudfront.net/icons/red-logo.png',
        originIcon: 'https://static.phdvasia.com/mx/icons/gray-logo.png'
      })

      if (outlet.distance <= 10) {
        bounds.extend(
          new google.maps.LatLng(
            marker.getPosition().lat(),
            marker.getPosition().lng()
          )
        )
      }
      function toggleBounce() {
        if (marker.getAnimation() !== null) {
          marker.setAnimation(null)
        } else {
          marker.setAnimation(google.maps.Animation.BOUNCE)
        }
      }
      marker.addListener('click', function(e) {
        for (let i = 0; i < vm.markers.length; i++) {
          if (vm.markers[i].infowindow) {
            vm.markers[i].infowindow.close()
          }
        }
        vm.onChangeOutlet(i)
        infowindow.open(vm.map, marker)
      })
      marker.addListener('mouseover', function(e) {
        vm.highlight = i
        toggleBounce()
      })
      marker.addListener('mouseout', function(e) {
        vm.highlight = null
        toggleBounce()
      })
      return marker
    },

    /**
     * Draw maps
     */
    drawMaps(skipRenderMainMarker = false, newCenter = undefined) {
      var vm = this
      this.mapCalled = 0

      this.initGeocoder()

      this.clearMapMarkers()

      // this.markers = []
      if (!skipRenderMainMarker) {
        if (newCenter) {
          this.locationCenter = newCenter
        }

        if (this.locationCenter.lat != 0 && this.locationCenter.lng != 0) {
          let latlng = new google.maps.LatLng(
            this.locationCenter.lat,
            this.locationCenter.lng
          )

          const infowindow =
            vm.orderType === vm.$const.DELIVERY
              ? new google.maps.InfoWindow({
                  content:
                    '<div class="address-confirmation-container p-15 cond" data-synth="address-confirmation-container"><div><div class="typo-l6 text-center mb-15"><span>' +
                    this.$t('localization.modal.top_text') + 
                    '</span></div><p class="typo-l6 text-grey mb-20 mt-20"><span>' +
                    this.$t('localization.modal.content_text') + 
                    '<br /><br /><span>' + this.$t('localization_exterior_number_message') + '</span><br />'+
                    '<input id="exterior-number" name="exterior-number" data-synth="exterior-number" placeholder="' + vm.$t('localization.put_correct_address_number') + '" type="text" class="input mb-10 mt-10" pattern="[a-zA-Z0-9 ]+">' +
                    '</span></p><button class="button button--primary confirm-delivery" data-synth="confirm-delivery" onclick="infowindowClick(-1)"><span>' +
                    vm.$t('continuar') +
                    '</span></button></div></div>',
                  maxWidth: 270
                })
              : undefined

          let nMarket = new google.maps.Marker({
            position: {
              lat: latlng.lat(),
              lng: latlng.lng()
            },
            map: this.map,
            draggable: false,
            title: 'estas aqui',
            icon: 'https://static.phdvasia.com/mx/icons/pin.png',
            infowindow
          })

          if (infowindow) {
            infowindow.open(this.map, nMarket)
          }

          google.maps.event.addListener(this.map, 'drag', function() {
            nMarket.setPosition(this.getCenter())
          })

          const dragZoomEnd = function() {
            nMarket.setPosition(this.getCenter())
            vm.$root.$emit('dragged', true)
            vm.logging.pinMoved = true
            vm.logging.googleRequest = JSON.stringify({
              lat: nMarket.getPosition().lat(),
              lng: nMarket.getPosition().lng()
            })
            vm.geocoder.geocode(
              {
                location: {
                  lat: nMarket.getPosition().lat(),
                  lng: nMarket.getPosition().lng()
                }
              },
              function(results, status) {
                if (status === 'OK') {
                  const mapLocationElement = document.getElementById(
                    'mapLocation'
                  )
                  if (mapLocationElement) {
                    let place = undefined
                    const MAP_CALLED_CHECK_COUNT = vm.orderType == vm.$const.CARRYOUT ? 0 : 1
                    if (vm.mapCalled > MAP_CALLED_CHECK_COUNT) {
                      place = results[0]
                      vm.locationCenter.lat = nMarket.getPosition().lat()
                      vm.locationCenter.lng = nMarket.getPosition().lng()
                    } else {
                      place = vm.searchAutoCompleteComponents
                      vm.locationCenter.lat = place.geometry.location.lat()
                      vm.locationCenter.lng = place.geometry.location.lng()
                    }
                    vm.logging.googleResponse = JSON.stringify(place)
                    mapLocationElement.value = place.formatted_address || ''
                    vm.deliveryAddress = mapLocationElement.value
                    vm.searchAutoCompleteComponents = place
                    vm.mapCalled++
                  }
                  // vm.locationCenter.lat = nMarket.getPosition().lat()
                  // vm.locationCenter.lng = nMarket.getPosition().lng()
                  let data = {
                    location:
                      nMarket.getPosition().lng() +
                      ',' +
                      nMarket.getPosition().lat(),
                    order_type: vm.orderType.charAt(0),
                    limit: 4
                  }
                  if (vm.orderType === vm.$const.CARRYOUT) {
                    vm.axios.apiOutlet.checkLocation(data, function(response) {
                      if (response.data.status) {
                        if (
                          response.data.data &&
                          response.data.data.items.length > 0
                        ) {
                          vm.$root.$emit(
                            'numOutlets',
                            response.data.data.items.length
                          )
                          for (const store of response.data.data.items) {
                            if (!vm.outletList.find(ol => ol.id === store.id)) {
                              vm.outletList.push(store)
                              const marker = vm.createMarkerForOutlet(
                                store,
                                vm.outletList.length - 1,
                                bounds
                              )
                              vm.markers.push(marker)
                            }
                          }
                          // vm.drawMaps(true)
                        } else {
                          vm.$root.$emit('numOutlets', 0)
                        }
                      }
                    })
                  }
                }
              }
            )
          }

          google.maps.event.addListener(this.map, 'dragend', dragZoomEnd)
          google.maps.event.addListener(this.map, 'zoom_changed', dragZoomEnd)

          this.markers.push(nMarket)
        }
      } else {
        this.markers.push(this.lastMarker)
      }
      let bounds = new google.maps.LatLngBounds()
      if (this.lastMarker) {
        bounds.extend(
          new google.maps.LatLng(
            this.lastMarker.getPosition().lat(),
            this.lastMarker.getPosition().lng()
          )
        )
      } else {
        bounds.extend(
          new google.maps.LatLng(
            this.markers[0].getPosition().lat(),
            this.markers[0].getPosition().lng()
          )
        )
      }

      // NOTE: Store pin is not supposed to be put on map
      for (let i = 0; i < this.outletList.length; i++) {
        const marker = vm.createMarkerForOutlet(this.outletList[i], i, bounds)
        this.markers.push(marker)
        bounds.extend(
          new google.maps.LatLng(marker.position.lat(), marker.position.lng())
        )
      }

      // const renderMarker = this.lastMarker ? this.lastMarker : this.markers[0]
      // if (renderMarker) {
      //   this.map.panTo(
      //     new google.maps.LatLng(
      //       renderMarker.getPosition().lat(),
      //       renderMarker.getPosition().lng()
      //     )
      //   )
      // }

      this.map.fitBounds(bounds)
    },
    loadScriptMap() {
      (function(d, s, id) {
        var js,
          fjs = d.getElementsByTagName(s)[0]
        if (d.getElementById(id)) return
        js = d.createElement(s)
        js.id = id
        js.src =
          'https://maps.googleapis.com/maps/api/js?key=' +
          keApi +
          '&libraries=places&language=' +
          language +
          '&region=' +
          region
        fjs.parentNode.insertBefore(js, fjs)
      })(document, 'script', 'google-maps-jssdk')
    },
    /**
     * Trigger before open modal popup
     * */
    beforeOpen(event) {
      this.initMap()
      // this.clearMapMarkers(false)
      this.drawMaps()
      this.onChangeOutlet(0)
      document.body.classList.add('modal-open')
      // if lastMarker is not found, try locationCenter
      let bounds = new google.maps.LatLngBounds()
      if (this.lastMarker) {
        bounds.extend(
          new google.maps.LatLng(
            this.lastMarker.getPosition().lat(),
            this.lastMarker.getPosition().lng()
          )
        )
        this.map.fitBounds(bounds)
        this.map.setCenter(
          new google.maps.LatLng(
            this.lastMarker.getPosition().lat(),
            this.lastMarker.getPosition().lng()
          )
        )
      } else {
        bounds.extend(
          new google.maps.LatLng(
            this.locationCenter.lat,
            this.locationCenter.lng
          )
        )
        this.map.setCenter(
          new google.maps.LatLng(
            this.locationCenter.lat,
            this.locationCenter.lng
          )
        )
      }
    },
    beforeOpenDraw(event) {
      if (this.locationObject.lat && this.locationObject.lng) {
        this.initMap()
        this.getAddressByLatLog({
          lat: parseFloat(this.locationObject.lat),
          lng: parseFloat(this.locationObject.lng)
        })
        this.showMapWithLocation()
        document.body.classList.add('modal-open')
      }
    },
    /**
     * Trigger before close modal popup
     * */
    beforeClose(event) {
      this.mapOpen = false
      document.body.classList.remove('modal-open')

      if (this.searchAutoCompleteComponents.formatted_address) {
        this.searchAutoComplete = this.searchAutoCompleteComponents.formatted_address
        document.getElementById(
          'address-autocomplete'
        ).value = this.searchAutoCompleteComponents.formatted_address
      }
    },
    beforeCloseDraw(event) {
      document.body.classList.remove('modal-open')
    },
    clearShowPopupMessage(json) {
      this.$notify({
        group: 'notifications',
        clean: true,
        speed: 1
      })

      this.$notify(json)
    },
    openPopUp: function() {
      let body = document.body
      body
        .getElementsByClassName('delivery-info-overlay')[0]
        .classList.add('open')
    }
  },
  components: {
    vSelect
  }
}
</script>
<style></style>
<style scoped>
a:focus {
  border: 1px solid #719ece !important;
}

.map-modal-fullscreen {
  width: auto !important;
}

.left-icon-address {
  width: 30px;
  flex: 0 0 30px;
  padding-left: 0;
  padding-right: 0;
}

.contain-outlet {
  max-height: 520px;
  overflow-y: auto;
}

.disposition-title {
  font-size: 14px;
}

@media (min-width: 768px) {
  .disposition-title {
    font-size: 18px;
  }
}

@media (max-width: 768px) {
  .icon-delivery {
    display: none;
  }
  .icon-collection {
    display: none;
  }
}

#mapLocalization {
  height: 238px;
  width: 100%;
}

@media screen and (min-width: 48em) {
  #mapLocalization {
    height: 455px;
  }
}

li.outlet-item:hover,
.highlight {
  background: #3053691a;
}

.suggestion-item {
  border-bottom: 1px solid rgba(223, 223, 227, 0.8);
  cursor: pointer;
}
.suggestion-item:hover {
  background: rgba(223, 223, 227, 0.8);
}
.suggestion-item:last-of-type {
  border-bottom: none;
  padding-bottom: 10px;
}
.contactless-header {
  margin: 0;
  font-size: 16px;
  line-height: 1.5;
  text-transform: uppercase;
  font-weight: 600;
}
.pointer {
  cursor: pointer;
}
</style>
