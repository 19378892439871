import moment from 'moment-timezone'
import {sha256} from 'js-sha256'
import constants from '../constants.js'
import _ from 'lodash'
import main from '../main'
import ASAPPopup from '../components/popup/OrderASAP'

const UNIQUE_KEY_PROP = '__unique_key_prop__'
const KEY_PREFIX = '__key_prefix__' + Date.now() + '_'
let uid = 0
const isObject = val => val !== null && typeof val === 'object'
const genUniqueKey = obj => {
  if (isObject(obj)) {
    if (UNIQUE_KEY_PROP in obj) {
      return obj[UNIQUE_KEY_PROP]
    }
    const value = KEY_PREFIX + uid++
    Object.defineProperty(obj, UNIQUE_KEY_PROP, {value})
    return value
  }
  return obj
}

export default {
  data: function () {
    return {
      cart: {},
      disposition: {}
    }
  },
  methods: {
    genUniqueKey,
    isMobile: function () {
      return window.innerWidth <= 768
    },
    /**
     * Get full url
     * */
    url: function (link) {
      return process.env.BASE_URL + '/' + link
    },
    /**
     * Redirect page
     * */
    redirect: function (url) {
      window.location.href = url
    },
    /**
     * Fix modal scroll on mobile and tablet
     * */
    fixMobileTabletScroll: function (modalState) {
      if (/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)) {
        const bodyElement = document.getElementsByTagName('body')[0]
        if (modalState === 'OPEN') {
          bodyElement.style.position = 'fixed';
          bodyElement.style.top = `-${window.scrollY}px`;
        } else if (modalState === 'CLOSE') {
          bodyElement.style.position = '';
          bodyElement.style.top =  '';
        }
      }
    },
    /**
     * Get current path
     * */
    getCurrentPath () {
      return window.location.pathname
    },
    /**
     * Checking has disposition
     * */
    checkPosition: function (vm) {
      return vm.$session.has('disposition') && vm.$session.get('disposition').outletId !== undefined
    },
    /**
     * Checking has disposition
     * */
    getOutletCode: function (vm) {
      return vm.checkPosition(vm) ? vm.$session.get('disposition').outletCode : ''
    },
    /**
     * Check empty variable
     * */
    empty: function (param) {
      if (param === null) {
        return true
      }
      if (typeof param === 'object') {
        return Object.keys(param).length <= 0
      }

      if (typeof param === 'string') {
        return param.length <= 0
      }

      if (param instanceof Array) {
        return param.length <= 0
      }
      return false
    },
    /**
     * Get delivery info
     * */
    getDeliveryInfo (vm) {
      let disposition = vm.$session.has('disposition') ? vm.$session.get('disposition') : ''
      if (typeof disposition.outletId === 'undefined') return {orderType: '', addressInfo: ''}
      let strAddress = disposition.orderType === vm.$const.DELIVERY ? disposition.locationName : disposition.outletName
      if (typeof strAddress === 'undefined' || !strAddress) {
        return {orderType: '', addressInfo: ''}
      }
      let addressInfo = strAddress
      let orderType = disposition.orderType === vm.$const.DELIVERY ? vm.$const.DELIVERY.toLowerCase() : vm.$const.CARRYOUT.toLowerCase()
      let info = {orderType, addressInfo}
      return info
    },
    convertDateTimeToUTC (datetime) {
      if (!datetime) {
        // Make the URL more static so access-control-max-age can take effect
        return moment().minute(moment().minute() > 30 ? 30 : 0).second(0).utc().format('YYYY-MM-DD HH:mm:ss')
      }

      let chkdate = moment(datetime)
      if (!chkdate.isValid()) {
        return moment().utc().format('YYYY-MM-DD HH:mm:ss')
      }
      return chkdate.utc().format('YYYY-MM-DD HH:mm:ss')
    },
    convertHour (hour) {
      if (hour == 'ASAP') {
        return hour
      }
      return moment(hour, ['HH:mm']).format('HH:mm')
    },
    convertUTCToClientTime (datetime) {
      if (!datetime) {
        return ''
      }
      return moment.utc(datetime).local()
    },
    convertUTCToTimeZone (datetime, timezone) {
      if (!datetime) {
        return moment.utc(new Date()).tz(timezone)
      }
      return moment.utc(datetime).tz(timezone)
    },
    convertDateTimeFromTimezoneToUTC (timezone, datetime) {
      if (!timezone) {
        return this.convertDateTimeToUTC(datetime)
      }
      if (!datetime) {
        return moment.tz(timezone).utc().format('YYYY-MM-DD HH:mm:ss')
      }

      let chkdate = moment.tz(datetime, timezone)
      if (!chkdate.isValid()) {
        return moment.tz(timezone).utc().format('YYYY-MM-DD HH:mm:ss')
      }
      return chkdate.utc().format('YYYY-MM-DD HH:mm:ss')
    },
    convertToUTCIfHasTimezone (vm, datetime) {
      let disposition = vm.$session.get('disposition')
      let timezone = false
      if (typeof disposition !== 'undefined' && typeof disposition['collectionTime'] !== 'undefined') {
        timezone = disposition['timezone']
      }
      if (!timezone) {
        return this.convertDateTimeToUTC(datetime)
      }
      return this.convertDateTimeFromTimezoneToUTC(timezone, datetime)
    },
    convertStringSnakeCase(str) {
      if (!str || typeof str === 'undefined' || str.length <= 0) {
        return ''
      }

      // START <space> sanitizer
      const regxReduceSpace = /\s{2,}/gi
      while (regxReduceSpace.test(str)) {
        str = str.replace(regxReduceSpace, ' ')
      }
      str = str.trim()
      // END ./ <space> sanitizer

      let arrstr = str.split(' ')
      let result = ''

      arrstr.forEach((val, i) => {
        var lowstr = arrstr[i].toLowerCase()

        // add '-' character, except for LAST WORD of string
        if (i < arrstr.length - 1) {
          result += lowstr + '-'
        } else {
          result += lowstr
        }
      })

      return result.trim()
    },
    /**
     * Get collection time (client timezone)
     * Show on header main and checkout page
     * */
    getTimePicker (vm, isHtml = false) {
      let disposition = vm.$session.get('disposition')
      let collectionTime = false
      let timezone = false
      if (typeof disposition !== 'undefined' && typeof disposition['collectionTime'] !== 'undefined') {
        collectionTime = disposition['collectionTime']
        timezone = disposition['timezone']
      }

      let today = !timezone ? moment(new Date()).format('YYYY-MM-DD') : moment.tz(timezone).format('YYYY-MM-DD')
      let nextday = !timezone ? moment(new Date()).add(1, 'days').format('YYYY-MM-DD') : moment.tz(timezone).add(1, 'days').format('YYYY-MM-DD')
      let pickedDay = collectionTime ? collectionTime.pickedDay : ''
      let pickedHours = collectionTime ? collectionTime.pickedHours : ''
      let result = ''
      let isAsap = false

      if (!vm.$session.has('disposition') || (disposition['advanceOrder'] === 'undefined' || disposition['advanceOrder'] === 0 || disposition['advanceOrder'] == '')) {
        result = vm.$t('asap') || ''
        isAsap = true
      } else if (pickedDay === today) {
        result = vm.$t('today') || ''
      } else if (pickedDay === nextday) {
        result = vm.$t('tomorrow') || ''
      } else {
        result = collectionTime.pickedDay
      }

      if (isHtml) {
        result = '<span>' + result + '</span>'
      }

      if (!isAsap) {
        if (isHtml) {
          result += '<span>' + this.convertHour(pickedHours) + '</span>'
        } else {
          result += ' ' + this.convertHour(pickedHours)
        }
      }

      return result
    },
    /**
     *
     * Get collection time (client timezone)
     * To pass ordertime to api (cart api)
     * return YYYY-MM-DD HH:mm:ss
     * */
    getTimePickerDate (vm) {
      let disposition = vm.$session.get('disposition')
      let today = moment(new Date()).format('YYYY-MM-DD')
      let collectionTime = today + ' ' + moment(new Date()).format('HH:mm')
      if (typeof disposition !== 'undefined' && typeof disposition['collectionTime'] !== 'undefined') {
        collectionTime = disposition['collectionTimeValue'] + ':00'
        let timezone = disposition['timezone']
        if (disposition['advanceOrder'] === 'undefined' || disposition['advanceOrder'] === 0 || disposition['advanceOrder'] == '') {
          return moment.tz(timezone).local().format('YYYY-MM-DD HH:mm:ss')
        }
        let datatime = moment.tz(collectionTime, timezone)
        if (datatime.isValid) {
          return datatime.local().format('YYYY-MM-DD HH:mm:ss')
        }
      }
      if (!vm.$session.has('disposition')) {
        collectionTime = today + ' ' + moment(new Date()).format('HH:mm:ss')
      }
      return collectionTime
    },
    getTimePickerDateNoneConvert (vm) {
      let disposition = vm.$session.get('disposition')
      let collectionTime = moment(new Date())
      if (typeof disposition !== 'undefined' && typeof disposition['collectionTime'] !== 'undefined') {
        collectionTime = disposition['collectionTimeValue'] + ':00'
        let timezone = disposition['timezone']
        if (disposition['advanceOrder'] === 'undefined' || disposition['advanceOrder'] === 0 || disposition['advanceOrder'] == '') {
          return moment.tz(timezone)
        }
        let datatime = moment.tz(collectionTime, timezone)
        if (datatime.isValid) {
          return datatime
        }
      }
      if (!this.$session.has('disposition')) {
        collectionTime = moment(new Date())
      }
      return collectionTime
    },
    /**
     * Get payment name
     * */
    getPaymentName (val) {
      let name = ''
      switch (val) {
        case 7:
          name = 'Creadit Card'
          break
        case 5:
          name = 'Maybank2U'
          break
        case 9:
          name = 'Cash'
          break
        case 4:
          name = 'Master Card'
          break
      }
      return name
    },
    /**
     * Convert string
     * */
    convertString (str) {
      if (typeof str === undefined || typeof str === 'undefined' || str.length <= 0) {
        return ''
      }
      let arrstr = str.split(' ')
      let result = ''
      for (var val in arrstr) {
        var lowstr = arrstr[val].toLowerCase()
        var upstr = lowstr.charAt(0).toUpperCase() + lowstr.slice(1)
        result += upstr + ' '
      }
      return result.trim()
    },
    /**
     * Format number
     * */
    formatNum (num) {
      num = num || 0
      return parseFloat(Math.round(num * 100) / 100).toFixed(constants.CURRENCY_FORMAT)
    },
    /**
     * Format number
     * */
    formatNumDisplay (num) {
      num = num || 0
      return parseFloat(Math.round(num * 100) / 100).toFixed(constants.CURRENCY_FORMAT).replace(constants.CURRENCY_RGX, constants.CURRENCY_DEC_POINT)
    },
    formatNum2Fix (num) {
      num = num || 0
      return parseFloat(Math.round(num * 100) / 100).toFixed(2)
    },
    checkNum (num) {
      return parseFloat(num || 0)
    },
    // sumNum (num1, num2) {
    //   num1 = parseFloat(num1 || 0)
    //   num2 = parseFloat(num2 || 0)
    //   let num = num1 + num2
    //   return (Math.round(num * 100) / 100).toFixed(2)
    // },
    sumNum (num1, num2, num3) {
      num1 = parseFloat(num1 || 0)
      num2 = parseFloat(num2 || 0)
      num3 = parseFloat(num3 || 0)
      let num = num1 + num2 + num3
      return (Math.round(num * 100) / 100).toFixed(constants.CURRENCY_FORMAT)
    },
    sumNumWithoutFormat (num1, num2) {
      num1 = parseFloat(num1 || 0)
      num2 = parseFloat(num2 || 0)
      let num = num1 + num2
      return Math.round(num * 100) / 100
    },
    clearNotification (vm, group) {
      group = group || ''
      vm.$notify({
        group: group,
        clean: true
      })
    },
    /**
     * Format number
     * */
    formatPercent (num) {
      return parseFloat(Math.round(num * 100) / 100).toFixed(0)
    },
    /**
     * Checking valid email field
     * @param email
     * @returns {boolean}
     */
    validEmail (email) {
      // let re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
      let re = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/
      return re.test(email)
    },
    hashEmail(email) {
      return sha256(email.trim().toLowerCase())
    },
    getUserDatalayer (vm) {
      let userinfo = vm.$auth.check() ? vm.$auth.user() : undefined
      const convertStringDateToObj = (date) => {
        if (!date) {
          return null;
        }
        const [, year, month, day] = date.match(/(\d{4})-(\d{2})-(\d{2})/)
        return {
          year: Number(year),
          month: Number(month),
          day: Number(day),
        }
      }
      
      let user = userinfo
        ? {
          'status': 'logged-in',
          customer: {
            'id': this.hashEmail(userinfo.email),
            'status': ((userinfo.outlets != null && userinfo.outlets.length > 0) || (userinfo.locations != null && userinfo.locations.length)) ? 'repeat' : 'new',
            first_name: userinfo.first_name,
            last_name: userinfo.last_name,
            email: userinfo.email.trim().toLowerCase(),
            dob: convertStringDateToObj(userinfo.birthdate),
            home_city: undefined,
            gender: userinfo.gender
              ? (userinfo.gender == 'M'
                ? 'm'
                : 'f')
              : null,
            email_subscribe: true,
            push_subscribe: true,
            country: 'PR',
            phone: userinfo.phone,
          },
          'PH_User_Device': 'website',
          'external_id': this.hashEmail(userinfo.email),
          device: {
            typeRollup: this.isMobile() ? 'mobile' : 'desktop'
          }
        }
        : {
          status: 'logged-out',
          'PH_User_Device': 'website',
          customer: {},
          'external_id': '',
          'device': {
            'typeRollup': this.isMobile() ? 'mobile' : 'desktop'
          }
        }
      return user
    },
    getFlatformDatalayer (vm) {
      let flatform = vm.$const.DATA_LAYER.PLATFORM
      flatform.environment = process.env.NODE_ENV
      flatform.localeLanguage = vm.$i18n.locale
      return flatform
    },
    /**
     * tracking all page
     * @param pageInfo is a object includes name and type
     *  sample { 'name': 'homepage.home',  'type': 'homepage'}
     */
    eventTrackingScreenLoad (vm, pageInfo) {
      //  console.info('+++++++++++++eventTrackingScreenLoad++++++++++++++')
      let data = {
        'event': 'screen.load.auto.dataLayer.load',
        'platform': this.getFlatformDatalayer(vm),
        'screen': pageInfo.screen,
        'user': this.getUserDatalayer(vm)
      }
      // if (pageInfo.modules) {
      //   data.modules = pageInfo.modules
      // }
      // if (pageInfo.assets) {
      //   data.assets = pageInfo.assets
      // }
      //  console.info('+++++++++++++eventTrackingScreenLoad data ++++++++++++++')
      //  console.info(data)
      //  console.info('+++++++++++++eventTrackingScreenLoad end ++++++++++++++')
      dataLayer.push(data)
    },
    /**
     * tracking success localizaion
     */
    eventTrackingLocalisationSuccess (vm) {
      //  console.info('+++++++++++++eventTrackingLocalisationSuccess++++++++++++++')
      let data = {
        'event': 'user.interact.auto.success.localisationSuccess',
        'cart': this.generateCartBasic(vm)
      }
      //  console.info('+++++++++++++eventTrackingLocalisationSuccess data ++++++++++++++')
      //  console.info(data)
      //  console.info('+++++++++++++eventTrackingLocalisationSuccess end ++++++++++++++')
      dataLayer.push(data)
    },
    eventTrackingLocalisationError (data) {
      //  console.info('+++++++++++++eventTrackingLocalisationError++++++++++++++')
      let layer = {
        'event': 'user.interact.auto.error.localisationError',
        'error': {
          'code': data.code,
          'type': 'geolocation',
          'error': data.error
        }
      }
      //  console.info('+++++++++++++eventTrackingLocalisationError data ++++++++++++++')
      //  console.info(layer)
      //  console.info('+++++++++++++eventTrackingLocalisationError end ++++++++++++++')
      dataLayer.push(layer)
    },
    /**
     * @param items is list of products
     */
    eventTrackingProductLoad (vm, items, isHalf = false, isList = false) {
      let categoryUuid = vm.$session.get('categoryUuid') || vm.$store.getters.category_uuid
      let category = {}
      let categories = vm.$session.get('listCategory')
      let productids = []
      let products = []
      if (categories.length > 0) {
        for (let i in categories) {
          if (categories[i].uuid === categoryUuid) {
            category = categories[i]
            break
          }
        }
        if (this.empty(category)) {
          category = categories[0]
        }
      }
      if (isHalf) {
        //  console.info('+++++++++++++eventTrackingProductLoad half ++++++++++++++')
        productids.push(vm.$const.HALF_HALF_UUID)
        products.push({
          'id': vm.$const.HALF_HALF_UUID,
          'name': vm.$t('btn_hatf_halt'),
          'category': 'pizza',
          'variants': [],
          'value': {
            'displayGross': '0',
            'displayTax': '0'
          },
          'tax': {
            'type': '',
            'name': '',
            'size': ''
          },
          'purchaseMethod': 'main',
          'bundleItems': []
        })
      } else {
        if (!isList) {
          //  console.info('+++++++++++++eventTrackingProductLoad menu single ++++++++++++++')
          productids.push(items.uuid)
          products.push({
            id: items.uuid,
            name: items.name,
            category: category.name,
            variants: [],
            value: {
              displayGross: items.min_price,
              displayTax: 0
            },
            tax: {
              type: '',
              name: '',
              size: ''
            },
            purchaseMethod: 'main',
            bundleItems: []
          })
        } else {
          //  console.info('+++++++++++++eventTrackingProductLoad list ++++++++++++++')
          if (items && items.length > 0) {
            for (let i in items) {
              let menus = items[i].singles
              if (menus.length > 0) {
                for (let m in menus) {
                  productids.push(menus[m].uuid)
                  products.push({
                    id: menus[m].uuid,
                    name: menus[m].name,
                    category: category.name,
                    variants: [],
                    value: {
                      displayGross: menus[m].min_price,
                      displayTax: 0
                    },
                    tax: {
                      type: '',
                      name: '',
                      size: ''
                    },
                    purchaseMethod: 'main',
                    bundleItems: []
                  })
                }
              }
            }
          }
        }
      }
      let layer = {
        'event': 'product.load.auto.dataLayer.load',
        'products': productids,
        'assets': {
          'products': products
        }
      }
      //  console.info('+++++++++++++eventTrackingProductLoad data ++++++++++++++')
      //  console.info(layer)
      //  console.info('+++++++++++++eventTrackingProductLoad end ++++++++++++++')
      dataLayer.push(layer)
    },
    /**
     * the event is used for
     * 1 Combo detail: product detail page
     * 2 menu or half & half: product list page ( include all of crust and size)
     * 3 menu or half & half detail page: already add to cart
     * @param item is a product
     */
    eventTrackingProductDetail (vm, item, type, nameType = 'product-list', hideEvent = false, method = 'main', comboName = '') {
      //  console.info('+++++++++++++eventTrackingProductDetail ++++++++++++++')
      let categoryUuid = vm.$session.get('categoryUuid') || vm.$store.getters.category_uuid
      let category = {}
      let categories = vm.$session.get('listCategory')
      if (categories.length > 0) {
        for (let i in categories) {
          if (categories[i].uuid === categoryUuid) {
            category = categories[i]
            break
          }
        }
        if (this.empty(category)) {
          category = categories[0]
        }
      }
      let cart = this.generateCartBasic(vm, true)
      let variants = []
      let name = ''
      if (type != vm.$const.CARD_ITEM_TYPE.HALF) {
        if (item) {
          if (item.first_layers) {
            for (let f in item.first_layers) {
              let price = item.first_layers[f].price
              if (item.first_layers[f].second_layers != null && item.first_layers[f].second_layers.length > 0) {
                for (let s in item.first_layers[f].second_layers) {
                  price = item.first_layers[f].price + item.first_layers[f].second_layers[s].price
                  variants.push({
                    id: item.first_layers[f].first_id + '-' + item.first_layers[f].second_layers[s].second_id,
                    name: item.name + '-' + item.first_layers[f].name + '-' + item.first_layers[f].second_layers[s].name,
                    type: 'size-crust',
                    quantity: 1,
                    value: {
                      displayGross: price,
                      displayTax: 0
                    }
                  })
                }
              } else {
                variants.push({
                  id: item.first_layers[f].uuid,
                  name: item.name + '-' + item.first_layers[f].name,
                  type: 'size',
                  quantity: 1,
                  value: {
                    displayGross: price,
                    displayTax: 0
                  }
                })
              }
            }
          }
          name = nameType + '.' + category.slug + '.'
          if (type === vm.$const.CARD_ITEM_TYPE.COMBO) {
            name += 'multi-item.' + (comboName ? comboName + '.' : '')
          }
          name += item.name ? item.name.split(' ').join('-') : item.name
        }
      } else {
        name = nameType + '.pizza.half-and-half'
        item = {
          id: item.uuid || vm.$const.HALF_HALF_UUID,
          uuid: item.uuid || vm.$const.HALF_HALF_UUID,
          name: item.name || vm.$t('btn_hatf_halt'),
          price: 0
        }
      }
      let flatform = vm.$const.DATA_LAYER.PLATFORM
      flatform.environment = process.env.NODE_ENV
      flatform.localeLanguage = vm.$i18n.locale

      let data = {
        'event': 'screen.load.auto.dataLayer.load',
        'screen': {
          'name': name,
          'type': nameType
        },
        'platform': flatform,
        'user': {},
        'products': [item.single_uuid],
        'cart': cart,
        'assets': {
          'products': [{
            id: item.single_uuid || item.uuid,
            name: item.name,
            category: item.category ? item.category.sku : category.name,
            attributes: {},
            variants: variants,
            'value': {
              'displayGross': item.price,
              'displayTax': 0
            },
            'tax': {
              'type': '',
              'name': '',
              'size': 0
            },
            'purchaseMethod': method
          }]
        }
      }
      data.user = this.getUserDatalayer(vm)
      if (hideEvent) {
        delete data.event
      }
      //  console.info('+++++++++++++eventTrackingProductDetail data ++++++++++++++')
      //  console.info(data)
      //  console.info('+++++++++++++eventTrackingProductDetail end ++++++++++++++')
      dataLayer.push(data)
    },
    eventTrackingDeadEnds (action, label) {
      dataLayer.push({
        'event': 'deadEnd', 
        'eventCategory': 'Dead End', 
        'eventAction': action, 
        'eventLabel': label,
        'eventValue': 'undefined' // deliberately set undefined as string
      })
    },
    /**
     *
     */
    eventTrackingProductView(variantUUid = null, product = {}) {
      // console.info('+++++++++++++eventTrackingProductView data ++++++++++++++')
      let item = product.item || product
      let categoryUuid = this.$session.get('categoryUuid') || this.$store.getters.category_uuid
      let category = {}
      let categories = this.$session.get('listCategory')
      if (categories.length > 0) {
        for (let i in categories) {
          if (categories[i].uuid === categoryUuid) {
            category = categories[i]
            break
          }
        }
        if (this.empty(category)) {
          category = categories[0]
        }
      }
      const name = item.isHalf ? 'Half - Half' : this.convertStringSnakeCase(item.name_en || item.name) || ''
      const categoryName = this.convertStringSnakeCase(category.slug || category.name)
      let products = []
      if (product) {
        let bundleItems = []
        for (let i in product.combogroup) {
          let menu = product.combogroup[i].item
          let variantOb = {
            quantity: 1,
            'value': {
              'displayGross': 0,
              'displayTax': 0
            }
          }
          let price = menu.price
          let nameMenu = menu.name
          if (menu.hasOwnProperty('choosedFirst') && menu.choosedFirst != null && !this.empty(menu.choosedFirst)) {
            variantOb.id = menu.choosedFirst.uuid
            variantOb.value.displayGross += menu.choosedFirst.price*1
            price -= menu.choosedFirst.price*1
            variantOb.name = menu.name + '-' + menu.choosedFirst.name
            variantOb.name = menu.name
            if (variantOb.name.indexOf(menu.choosedFirst.name) >= 0) {
              variantOb.name = variantOb.name.replace(menu.choosedFirst.name, '').trim()
              nameMenu = variantOb.name
            }
            variantOb.name += '-' + menu.choosedFirst.name
            variantOb.type = 'size'
          }
          if (menu.hasOwnProperty('choosedSecond') && menu.choosedSecond != null && !this.empty(menu.choosedSecond)) {
            variantOb.name += '-' + menu.choosedSecond.name
            variantOb.id += '-' + menu.choosedSecond.uuid
            variantOb.type += '-crust'
            variantOb.value.displayGross += menu.choosedSecond.price*1
          }
          variantOb.name = this.convertStringSnakeCase(variantOb.name)
          bundleItems.push({
            productId: menu.uuid,
            category: item.category || '', // not yet from api
            quantity: '1',
            name: this.convertStringSnakeCase(nameMenu),
            purchaseMethod: 'deal',
            variant: variantOb,
            value: {
              displayGross: price,
              displayTax: 0
            }
          })
        }
        products.push({
          'id': item.uuid,
          'name': this.convertStringSnakeCase(item.name),
          'category': categoryName || '',
          'attributes': {},
          'variants': [],
          'value': {
            'displayGross': product.price,
            'displayTax': '0.00'
          },
          'tax': {
            'type': '',
            'name': '',
            'size': ''
          },
          'purchaseMethod': 'main',
          'bundleItems': bundleItems
        })
      }
      const layer = {
        'event': 'product.interact.manual.view',
        'variantId': variantUUid || '',
        'products': [
          item.uuid
        ],
        'name': name,
        'id': item.uuid || '',
        'category': categoryName || '',
        'price': item.price > 0 ? item.price : (item.min_price || '0'),
        'assets': {
          'products': products
        }
      }
      // console.info(layer)
      // console.info('+++++++++++++eventTrackingProductView end ++++++++++++++')
      dataLayer.push(layer)
    },
    eventTrackingAddToCart (vm, item, method = 'main', type = '', label = 'normal') {
      //  console.info('+++++++++++++eventTrackingAddToCart ++++++++++++++')
      let categoryUuid = vm.$session.get('categoryUuid') || vm.$store.getters.category_uuid
      let category = {}
      let categories = vm.$session.get('listCategory')
      if (categories.length > 0) {
        for (let i in categories) {
          if (categories[i].uuid === categoryUuid) {
            category = categories[i]
            break
          }
        }
        if (this.empty(category)) {
          category = categories[0]
        }
      }
      const name = item.isHalf ? 'Half - Half' : this.convertStringSnakeCase(item.name_en || item.name) || ''
      const categoryName = this.convertStringSnakeCase(category.slug || category.name)
      let layer = {
        'event': 'product.interact.manual.click.addToCart',
        'bundleItems': [],
        'product': {},
        'name': name,
        'id': item.uuid || '',
        'category': categoryName || '',
        'price': item.min_price || '0',
        'quantity': '1',
        'eventLabel': label
      }
      switch (type) {
        case vm.$const.CARD_ITEM_TYPE.SINGLE:
          layer.product.id = item.item.uuid || item.id
          layer.product.name = item.name
          layer.product.category = item.category
          layer.product.purchaseMethod = 'main'
          layer.product.value = {}
          layer.quantity = item.quantity || '1'
          layer.product.value.displayGross = item.second ? item.second.price : item.item.price
          layer.product.variants = {}

          if (typeof item.first !== 'undefined' && !vm.empty(item.first)) {
            layer.product.variants.id = item.first.first_id
            layer.product.variants.name = item.name + '-' + item.first.name
            if (typeof item.second !== 'undefined' && !vm.empty(item.second)) {
              layer.product.variants.id += '-' + item.second.second_id
              layer.product.variants.name += '-' + item.second.name
            }
          }
          break
        case vm.$const.CARD_ITEM_TYPE.COMBO:
          layer.product.id = item.id
          layer.product.purchaseMethod = 'main'
          layer.product.name = item.name
          layer.product.value = {}
          layer.product.value.displayGross = item.item ? item.item.base_price : item.price
          layer.product.quantity = item.quantity || '1'
          layer.product.category = 'deals'
          let bundleItems1 = []
          for (let i in item.items) {
            let menu = item.items[i]
            let variantOb = {
              quantity: 1,
              'value': {
                'displayGross': 0,
                'displayTax': 0
              }
            }
            let price = 0
            if (menu.item.category || menu.item.base_category.short_name === 'Pizzas') {
              const lastIndex = menu.item.name.lastIndexOf(' ')
              menu.item.name = menu.item.name.substring(0, lastIndex)
              if (menu.hasOwnProperty('first') && menu.first != null) {
                variantOb.id = menu.first.first_id
                price += menu.first.price
                variantOb.value.displayGross += menu.first.price
                variantOb.name = menu.item.name + '-' + menu.first.name
                variantOb.type = 'size'
              }
              if (menu.hasOwnProperty('second') && menu.second != null) {
                variantOb.name += '-' + menu.second.name
                variantOb.id += '-' + menu.second.second_id
                variantOb.type += '-crust'
                price += menu.second.price || 0
                variantOb.value.displayGross += menu.second.price
              }
            }
            bundleItems1.push({
              id: menu.item.id || menu.item.product_id,
              productId: menu.item.mapping_single_uuid || menu.item.uuid,
              category: menu.item.category || menu.item.base_category.short_name,
              quantity: item.quantity,
              name: menu.item.name,
              purchaseMethod: 'deal',
              variants: variantOb,
              value: {
                displayGross: price,
                displayTax: 0
              }
            })
          }
          layer.product.bundleItems = bundleItems1
          break
        case vm.$const.CARD_ITEM_TYPE.HALF:
          layer.productId = item.id
          let bundleItems = []
          for (let i in item.items) {
            let menu = item.items[i]
            let variantOb = {
              quantity: 1,
              'value': {
                'displayGross': 0,
                'displayTax': 0
              }
            }
            let price = menu.item.price
            if (menu.hasOwnProperty('first') && menu.first != null) {
              variantOb.id = menu.first.frist_id
              price += menu.first.price
              variantOb.name = menu.item.name + '-' + menu.first.name
              variantOb.type = 'size'
              variantOb.value.displayGross += menu.first.price
            }
            if (menu.hasOwnProperty('second') && menu.second != null) {
              variantOb.name += '-' + menu.second.name
              variantOb.id += '-' + menu.second.second_id
              variantOb.type += '-crust'
              price += menu.second.price
              variantOb.value.displayGross += menu.second.price
            }
            bundleItems.push({
              productId: menu.item.uuid,
              category: menu.item.category || '', // not yet from api
              quantity: '1',
              name: menu.item.name,
              purchaseMethod: 'half-half',
              variant: variantOb,
              value: {
                displayGross: price,
                displayTax: 0
              }
            })
          }
          layer.bundleItems = bundleItems
          break
      }
      //  console.info('+++++++++++++eventTrackingAddToCart data ++++++++++++++')
      //  console.info(layer)
      //  console.info('+++++++++++++eventTrackingAddToCart end ++++++++++++++')
      dataLayer.push(layer)
    },
    evenTrackingProductClick (vm, item, method = '') {
      let categoryUuid = this.$session.get('categoryUuid') || this.$store.getters.category_uuid
      let category = {}
      let categories = this.$session.get('listCategory')
      if (categories.length > 0) {
        for (let i in categories) {
          if (categories[i].uuid === categoryUuid) {
            category = categories[i]
            break
          }
        }
        if (this.empty(category)) {
          category = categories[0]
        }
      }
      const name = item.isHalf ? 'Half - Half' : this.convertStringSnakeCase(item.name_en || item.name) || ''
      const categoryName = this.convertStringSnakeCase(category.slug || category.name)
      let data = {
        'event': 'product.interact.manual.click',
        'interactionElement': {
          'method': method
        },
        'products': [item.uuid],
        'name': name,
        'id': item.uuid || '',
        'category': categoryName || '',
        'price': item.price > 0 ? item.price : (item.min_price || '0'),
        'variantId': [item.variantId]
      }
      //  console.info('+++++++++++++evenTrackingProductClick data ++++++++++++++')
      //  console.info(data)
      //  console.info('+++++++++++++evenTrackingProductClick end ++++++++++++++')
      dataLayer.push(data)
    },
    eventTrackingAddToDeal (vm, item, method = '') {
      //  console.info('+++++++++++++eventTrackingAddToDeal ++++++++++++++')
      let categoryUuid = vm.$session.get('categoryUuid') || vm.$store.getters.category_uuid
      let category = {}
      let categories = vm.$session.get('listCategory')
      if (categories.length > 0) {
        for (let i in categories) {
          if (categories[i].uuid === categoryUuid) {
            category = categories[i]
            break
          }
        }
        if (this.empty(category)) {
          category = categories[0]
        }
      }
      let variants = []
      if (typeof item.first !== 'undefined' && !vm.empty(item.first)) {
        let variant = {}
        let price = item.first.price
        variant.id = item.first.frist_id
        variant.name = item.name + '-' + item.first.name
        variant.type = 'size'
        if (typeof item.second !== 'undefined' && !vm.empty(item.second)) {
          variant.id += '-' + item.second.second_id
          variant.type += '-crust'
          variant.name += '-' + item.second.name
          price += item.second.price
        }

        variant.value = {
          displayGross: price,
          displayTax: 0
        }
        variants.push(variant)
      }
      let data = {
        'event': 'product.interact.manual.click',
        'interactionElement': {
          'method': method
        },
        'products': [{
          'id': item.item.single_uuid,
          'bundleItems': [
            {
              'id': item.id,
              'name': item.name,
              'category': category.name,
              'attributes': {},
              'variants': variants,
              'value': {
                'displayGross': item.price,
                'displayTax': 0
              },
              'tax': {
                'type': '',
                'name': '',
                'size': ''
              },
              'purchaseMethod': 'deal'
            }
          ]
        }]
      }
      //  console.info('+++++++++++++eventTrackingAddToDeal data ++++++++++++++')
      //  console.info(data)
      //  console.info('+++++++++++++eventTrackingAddToDeal end ++++++++++++++')
      dataLayer.push(data)
    },
    eventTrackingRemoveFromCart (item) {
      //  console.info('+++++++++++++eventTrackingRemoveFromCart++++++++++++++')
      let data = {
        'event': 'product.interact.manual.click.removeFromCart',
        'product': {
          'id': item.id,
          'name': item.name,
          'category': item.category,
          'variants': item.variants,
          'price': item.price || '0',
          'quantity': item.quantity
        }
      }
      //  console.info('+++++++++++++eventTrackingRemoveFromCart data ++++++++++++++')
      //  console.info(data)
      //  console.info('+++++++++++++eventTrackingRemoveFromCart end ++++++++++++++')
      dataLayer.push(data)
    },
    /**
     * @param variant is a menu
     *  {
          "id": "crispy-bacon",
          "productId": "pepperoni-feast",
          "type": "topping",
          "name": "crispy-bacon",
          "value": {
            "displayGross": "1.20",
            "displayTax": "0.24"
          }
        }
     */
    eventTrackingVariantSelect (item) {
      //  console.info('+++++++++++++eventTrackingVariantSelect ++++++++++++++')
      let topping = item.topping
      let first = item.first
      let price = 0
      if (topping) {
        price = topping.prices != null && topping.prices.hasOwnProperty(first.sku) ? topping.prices[first.sku] : 0
      } else {
        price = 0
      }

      let data = {
        'event': 'variant.interact.manual.click.select',
        'variant': {
          'id': topping ? topping.uuid : '',
          'productId': item.item ? item.item.uuid : '',
          'type': 'topping',
          'name': topping ? topping.name : '',
          'value': {
            'displayGross': price,
            'displayTax': '0'
          }
        }
      }
      //  console.info('+++++++++++++eventTrackingVariantSelect data ++++++++++++++')
      //  console.info(data)
      //  console.info('+++++++++++++eventTrackingVariantSelect end ++++++++++++++')
      dataLayer.push(data)
    },
    /**
     * @param variant is a menu
     *  {
          "id": "crispy-bacon",
          "productId": "pepperoni-feast",
          "type": "topping",
          "name": "crispy-bacon",
          "value": {
            "displayGross": "1.20",
            "displayTax": "0.24"
          }
        }
     */
    eventTrackingVariantUnSelect (item) {
      //  console.info('+++++++++++++eventTrackingVariantUnSelect++++++++++++++')
      let topping = item.topping
      let first = item.first
      let price = 0
      if (topping) {
        price = topping.prices != null && topping.prices.hasOwnProperty(first.sku) ? topping.prices[first.sku] : 0
      } else {
        price = 0
      }
      let data = {
        'event': 'variant.interact.manual.click.unselect',
        'variant': {
          'id': topping ? topping.uuid : '',
          'productId': item.item ? item.item.uuid : '',
          'type': 'topping',
          'name': topping ? topping.name : '',
          'value': {
            'displayGross': price,
            'displayTax': '0'
          }
        }
      }
      //  console.info('+++++++++++++eventTrackingVariantUnSelect data ++++++++++++++')
      //  console.info(data)
      //  console.info('+++++++++++++eventTrackingVariantUnSelect end ++++++++++++++')
      dataLayer.push(data)
    },
    eventTrackingCheckout (vm) {
      //  console.info('+++++++++++++eventTrackingCheckout++++++++++++++')
      let cart = this.generateCartFullForCheckout(vm)
      cart.checkoutStep = 1
      let data = {
        'event': 'screen.load.auto.dataLayer.load',
        'screen': {
          'name': 'checkout.details',
          'type': 'checkout'
        },
        'cart': cart,
        'user': this.getUserDatalayer(vm),
        'platform': this.getFlatformDatalayer(vm)
      }
      //  console.info('+++++++++++++eventTrackingCheckout data ++++++++++++++')
      //  console.info(data)
      //  console.info('+++++++++++++eventTrackingCheckout end ++++++++++++++')
      dataLayer.push(data)
    },
    /**
     * @param {Vue machine} vm
     * @param {
     *  name: string,
     *  email: string,
     *  phone: string
     * } userInfo
     */
    eventTrackingOrderPlaced(vm, userInfo) {
      let cart = this.generateCartFullForCheckout(vm)
      cart.checkoutStep = 1
      const userData = this.getUserDatalayer(vm)
      const nameParts = userInfo.name.split(' ')
      const first_name = nameParts[0]
      const last_name = nameParts.slice(1).join(' ')
      let data = {
        'event': 'screen.load.auto.dataLayer.load',
        'screen': {
          'name': 'checkout.details',
          'type': 'checkout'
        },
        'cart': cart,
        'user': {
          ...userData,
          external_id: this.hashEmail(userInfo.email),
          customer: {
            ...userData.customer,
            id: this.hashEmail(userInfo.email),
            phone: userInfo.phone,
            first_name: first_name,
            last_name: last_name,
            email: userInfo.email,
            email_subscribe: true,
            push_subscribe: true,
          }
        },
        'platform': this.getFlatformDatalayer(vm)
      }
      dataLayer.push(data)
    },
    eventTrackingPayment (vm, form) {
      //  console.info('+++++++++++++eventTrackingPayment ++++++++++++++')
      let cart = this.generateCartFullForCheckout(vm)
      cart.checkoutStep = 2
      let data = {
        'screen': {
          'name': 'checkout.payment',
          'type': 'checkout'
        },
        'forms': [form.id],
        'cart': cart,
        'assets': form
      }
      //  console.info('+++++++++++++eventTrackingPayment data ++++++++++++++')
      //  console.info(data)
      //  console.info('+++++++++++++eventTrackingPayment end ++++++++++++++')
      dataLayer.push(data)
    },
    /**
     * for order status page
     * @param data
     * @param orderTime
     * @returns {{id: string, checkoutStep: string, currency: *, discount: {lines: Array, value: {displayGross: *, displayTax: string}}, shipping: {value: {displayGross: *, displayTax: string}, type: string, storeId: *, deliveryTime: *}, value: {displayGross: number | string | * | PaymentItem, displayTax: number}}}
     */
    genCartForConfirmation (vm, data, orderTime) {
      let cart = {
        'id': data.uuid,
        'currency': data.currency,
        'lines': [],
        'discount': {
          lines: [],
          value: {
            'displayGross': data.total_discount, // total discount
            'displayTax': '0.00'
          }
        },
        'shipping': {
          'value': {
            'displayGross': data.delivery_fee || 0,
            'displayTax': '0.00'
          },
          'type': data.order_type.toUpperCase() === 'D' ? vm.$const.DELIVERY.toLowerCase() : vm.$const.CARRYOUT.toLowerCase(),
          'storeId': data.outlet_code,
          'deliveryTime': this.getTimePicker(vm)
        },
        'value': {
          'displayGross': data.total || 0, // total cart
          'displayTax': 0
        }
      }
      for (let i in data.order_items) {
        let menu = vm.lodash.cloneDeep(data.order_items[i])
        let item = {}
        if (menu.type === vm.$const.CARD_ITEM_TYPE.COMBO) {
          item.id = data.uuid + '-' + (parseFloat(i) + 1)
          item.product = {
            'id': menu.uuid || 0,
            'name': menu.name,
            'category': menu.base_category.short_name,
            'attributes': {},
            'variants': [],
            'value': {
              'displayGross': menu.price,
              'displayTax': '0'
            },
            'tax': {
              'type': '',
              'name': '',
              'size': ''
            },
            'purchaseMethod': 'main',
            'bundleItems': []
          }
          let bundleItems = []
          for (let c in menu.items) {
            let product = vm.lodash.cloneDeep(menu.items[c])
            let variantOb = {
              'value': {
                'displayGross': 0,
                'displayTax': '0'
              }
            }
            let fPrice = 0
            if (product.hasOwnProperty('first') && product.first != null && product.first.sku != '') {
              variantOb.id = product.first.first_id
              fPrice += product.first.price
              variantOb.name = product.name + '-' + product.first.name
              variantOb.type = 'size'
              if (product.first.hasOwnProperty('second') && product.first.second != null && product.first.second.sku != '') {
                variantOb.id += '-' + product.first.second.second_id
                variantOb.name += '-' + product.first.second.name
                variantOb.type += '-crust'
                fPrice += product.first.second.price
              }
            }
            variantOb.value.displayGross = fPrice
            bundleItems.push({
              productId: product.uuid,
              category: product.base_category.short_name || '', // not yet from api
              quantity: product.quantity,
              name: product.name,
              purchaseMethod: 'deal',
              variant: variantOb,
              value: {
                displayGross: product.total || 0,
                displayTax: 0
              }
            })
          }
          item.product.bundleItems = bundleItems
          item.quantity = menu.quantity
          item.value = {
            'displayGross': menu.total,
            'displayTax': '0'
          }
          item.discount = {
            lines: [],
            'value': {
              'displayGross': '',
              'displayTax': '0'
            }
          }
          if (menu.coupon_code != undefined && menu.coupon_code != null && !this.empty(menu.coupon_code)) {
            item.discount.lines.push(this.getCoupondetail2(menu.coupon_code, data.order_coupons))
          }
          let dprice = 0
          for (let d in item.discount.lines) {
            dprice += parseFloat(item.discount.lines[d].value.displayGross)
          }
          item.discount.value.displayGross = dprice
        } else if (menu.type === vm.$const.CARD_ITEM_TYPE.SINGLE) {
          item.id = data.uuid + '-' + (parseFloat(i) + 1)
          item.product = {
            'id': '',
            'name': '',
            'category': '',
            'attributes': {},
            'variants': [],
            'value': {
              'displayGross': menu.price,
              'displayTax': '0'
            },
            'tax': {
              'type': '',
              'name': '',
              'size': ''
            },
            'purchaseMethod': 'main',
            'bundleItems': []
          }
          item.quantity = menu.quantity
          item.value = {
            'displayGross': menu.total,
            'displayTax': '0'
          }
          item.discount = {
            lines: [],
            'value': {
              'displayGross': '',
              'displayTax': '0'
            }
          }
          for (let v in menu.items) {
            if (v === '0') {
              item.product.id = menu.items[v].uuid
              item.product.name = menu.items[v].name
              item.product.category = menu.items[v].base_category.short_name
            }
            if (menu.items[v].coupon_code != undefined && menu.items[v].coupon_code != null && !this.empty(menu.items[v].coupon_code)) {
              item.discount.lines.push(this.getCoupondetail2(menu.items[v].coupon_code, data.order_coupons))
            }
            if (typeof menu.items[v].first !== 'undefined' && !this.empty(menu.items[v].first) && menu.items[v].first.sku != '') {
              let variant = {}
              let price = menu.items[v].first.price
              variant.id = menu.items[v].first.first_id
              variant.name = menu.items[v].name + '-' + menu.items[v].first.name
              variant.type = 'size'
              if (typeof menu.items[v].first.second !== 'undefined' && !this.empty(menu.items[v].first.second) && menu.items[v].first.second.sku != '') {
                variant.type += '-crust'
                variant.name += '-' + menu.items[v].first.second.name
                price += menu.items[v].first.second.price
                variant.id += '-' + menu.items[v].first.second.second_id
              }
              variant.value = {
                displayGross: price,
                displayTax: 0
              }
              item.product.variants.push(variant)
            }
          }
          let dprice = 0
          for (let d in item.discount.lines) {
            dprice += parseFloat(item.discount.lines[d].value.displayGross)
          }
          item.discount.value.displayGross = dprice
        } else if (menu.type === vm.$const.CARD_ITEM_TYPE.HALF) {
          item.id = data.uuid + '-' + (parseFloat(i) + 1)
          item.product = {
            'id': vm.$const.HALF_HALF_UUID,
            'name': menu.name,
            'category': '',
            'attributes': {},
            'variants': [],
            'value': {
              'displayGross': menu.total,
              'displayTax': '0'
            },
            'tax': {
              'type': '',
              'name': '',
              'size': ''
            },
            'purchaseMethod': 'main',
            'bundleItems': []
          }
          item.quantity = menu.quantity
          item.value = {
            'displayGross': menu.total,
            'displayTax': '0'
          }
          item.discount = {
            lines: [],
            'value': {
              'displayGross': '',
              'displayTax': '0'
            }
          }
          if (menu.only_lunch) {
            item.product.id = vm.$const.HALF_HALF_LUNCH_UUID
          }
          let bundleItems = []
          for (let v in menu.items) {
            if (typeof menu.items[v].coupon_code !== 'undefined' && menu.items[v].coupon_code != null && !this.empty(menu.items[v].coupon_code)) {
              item.discount.lines.push(this.getCoupondetail2(menu.items[v].coupon_code, data.order_coupons))
            }
            let _menu = vm.lodash.cloneDeep(menu.items[v])
            let layerInfo = vm.lodash.cloneDeep(_menu)
            let variantOb = {
              quantity: 1,
              'value': {
                'displayGross': 0,
                'displayTax': 0
              }
            }
            let price = _menu.price || 0
            if (layerInfo.hasOwnProperty('first') && layerInfo.first != null && layerInfo.first.sku != '') {
              variantOb.id = layerInfo.first.uuid
              price += layerInfo.first.price
              variantOb.name = _menu.name + '-' + layerInfo.first.name
              variantOb.type = 'size'
              variantOb.value.displayGross += layerInfo.first.price
              if (layerInfo.first.hasOwnProperty('second') && layerInfo.first.second != null && layerInfo.first.second.sku != '') {
                variantOb.name += '-' + layerInfo.first.second.name
                variantOb.id += '-' + layerInfo.first.second.uuid
                variantOb.type += '-crust'
                price += layerInfo.first.second.price
                variantOb.value.displayGross += layerInfo.first.second.price
              }
            }

            bundleItems.push({
              productId: _menu.uuid,
              category: _menu.category || '', // not yet from api
              quantity: '1',
              name: _menu.name,
              purchaseMethod: 'half-half',
              variant: variantOb,
              value: {
                displayGross: price,
                displayTax: 0
              }
            })
          }
          item.product.bundleItems = bundleItems
          let dprice = 0
          for (let d in item.discount.lines) {
            dprice += parseFloat(item.discount.lines[d].value.displayGross)
          }
          item.discount.value.displayGross = dprice
        }
        cart.lines.push(item)
      }

      if (data.order_coupons) {
        for (let c in data.order_coupons) {
          if (data.order_coupons[c].type != vm.$const.COUPON_TYPE_ITEM) {
            cart.discount.lines.push({
              id: data.order_coupons[c].coupon_uuid,
              name: data.order_coupons[c].coupon_name,
              code: data.order_coupons[c].coupon_code,
              type: data.order_coupons[c].discount_type_name,
              size: data.order_coupons[c].discount_percent_unit || 0,
              value: {
                'displayGross': data.order_coupons[c].discount,
                'displayTax': '0.00'
              }
            })
          }
        }
      }
      return cart
    },
    eventTrackingConfirmation (vm, cart) {
      //  console.info('+++++++++++++eventTrackingConfirmation ++++++++++++++')
      let timezone = cart.timezone
      let orderTime = this.convertUTCToTimeZone(cart.collection_time, timezone)
      let data = {
        'event': 'transaction.load.auto.success.transactionSuccess',
        'user': this.getUserDatalayer(vm),
        'screen': {
          'name': 'checkout.confirmation',
          'type': 'checkout'
        },
        'transaction': {
          id: cart.uuid,
          currency: cart.currency,
          cart: this.genCartForConfirmation(vm, cart, orderTime.unix()),
          value: {
            'displayGross': cart.total || 0,
            'displayTax': 0
          },
          payment: {
            id: cart.order_payment.id,
            authId: '',
            type: cart.order_payment.method,
            timestamp: orderTime.unix()
          }
        }
      }
      //  console.info('+++++++++++++eventTrackingConfirmation data ++++++++++++++')
      //  console.info(data)
      //  console.info('+++++++++++++eventTrackingConfirmation end ++++++++++++++')
      dataLayer.push(data)
    },
    eventTrackingOrderSubmit (id) {
      let data = {
        'event': 'cart.interact.manual.submit.orderSubmit',
        'cart': id
      }
      dataLayer.push(data)
    },
    eventTrackingOrderError (data) {
      //  console.info('+++++++++++++eventTrackingOrderError ++++++++++++++')
      let layer = {
        'event': 'cart.load.auto.error.orderError',
        'cart': {
          'id': data.id
        },
        'error': {
          'code': data.code,
          'type': data.type,
          'message': data.message
        }
      }
      //  console.info('+++++++++++++eventTrackingOrderError data ++++++++++++++')
      //  console.info(layer)
      //  console.info('+++++++++++++eventTrackingOrderError end ++++++++++++++')
      dataLayer.push(layer)
    },
    eventTrackingContactus (contactus) {
      //  console.info('+++++++++++++eventTrackingContactus ++++++++++++++')
      let data = {
        'screen': {
          'name': 'contact-us.contact-us',
          'type': 'contact-us'
        },
        'forms': [
          contactus.id
        ],
        'assets': {
          'forms': [
            contactus
          ]
        }
      }
      //  console.info('+++++++++++++eventTrackingContactus data ++++++++++++++')
      //  console.info(data)
      //  console.info('+++++++++++++eventTrackingContactus end ++++++++++++++')
      dataLayer.push(data)
    },
    /**
     *
     * @param data
     */
    eventTrackingModuleLoad (data) {
      //  console.info('+++++++++++++eventTrackingModuleLoad ++++++++++++++')
      let layer = {
        'event': 'module.load.auto.dataLayer.load',
        'module': data.modules,
        'assets': data.assets
      }
      //  console.info('+++++++++++++eventTrackingModuleLoad data ++++++++++++++')
      //  console.info(layer)
      //  console.info('+++++++++++++eventTrackingModuleLoad end ++++++++++++++')
      dataLayer.push(layer)
    },
    eventTrackingCoupon (vm, data) {
      vm.$cart.init(vm)
      //  console.info('+++++++++++++eventTrackingCoupon++++++++++++++')
      let layer = {
        'event': 'cart.interact.manual.submit.promoCodeAdded',
        'discountLine': {
          'cartId': vm.cart.uuid || 0,
          'id': 'cart-disc-123',
          'name': data.name,
          'type': 'fixed',
          'size': data.price,
          'value': {
            'displayGross': data.price,
            'displayTax': '0.00'
          },
          'code': data.couponCode,
          'category': 'listed'
        }
      }
      //  console.info('+++++++++++++eventTrackingCoupon data ++++++++++++++')
      //  console.info(layer)
      //  console.info('+++++++++++++eventTrackingCoupon end ++++++++++++++')
      dataLayer.push(layer)
    },
    /**
     *
     * @param data
     */
    eventTrackingModuleItemClick (data) {
      //  console.info('+++++++++++++eventTrackingModuleItemClick data ++++++++++++++')
      let layer = {
        'event': 'moduleItem.interact.manual.click',
        'moduleItem': {
          'id': data.id,
          'moduleId': data.moduleId
        }
      }
      //  console.info('+++++++++++++eventTrackingModuleItemClick data ++++++++++++++')
      //  console.info(layer)
      //  console.info('+++++++++++++eventTrackingModuleItemClick end ++++++++++++++')
      dataLayer.push(layer)
    },
    getCoupondetail (code, data) {
      let coupon = {}
      for (let c1 in data) {
        if (data[c1].code === code) {
          coupon = {
            id: data[c1].coupon_uuid,
            name: data[c1].name,
            type: data[c1].discount_type_name,
            size: data[c1].discount_percent_unit || 0,
            'value': {
              'displayGross': data[c1].discount_value,
              'displayTax': '0'
            },
            code: data[c1].code
          }
          break
        }
      }
      return coupon
    },
    getCoupondetail2 (code, data) {
      let coupon = {}
      for (let c1 in data) {
        if (data[c1].coupon_code === code) {
          coupon = {
            id: data[c1].coupon_uuid,
            name: data[c1].coupon_name,
            type: data[c1].discount_type_name,
            size: data[c1].discount_percent_unit || 0,
            'value': {
              'displayGross': data[c1].discount,
              'displayTax': '0'
            },
            code: data[c1].coupon_code
          }
          break
        }
      }
      return coupon
    },
    generateCartFullForCheckout (vm) {
      vm.$cart.init(vm)
      let cart = {
        'id': vm.cart.uuid,
        'currency': vm.$const.CURRENCY_CODE,
        'shipping': {
          'value': {
            'displayGross': vm.cart.delivery_fee || 0,
            'displayTax': '0.00'
          },
          'discount': {},
          'type': vm.$disposition.getByKey(vm, 'orderType', '').toLowerCase(),
          'storeId': vm.$disposition.getByKey(vm, 'outletCode', ''),
          'deliveryTime': this.getTimePickerDateNoneConvert(vm).unix()
        },
        'value': {
          'displayGross': vm.cart.total || 0,
          'displayTax': 0
        }
      }
      cart.lines = []
      for (let i in vm.cart.data) {
        let menu = vm.lodash.cloneDeep(vm.cart.data[i])
        let item = {}
        if (menu.product_type === vm.$const.CARD_ITEM_TYPE.COMBO) {
          item.id = vm.cart.uuid + '-' + (parseFloat(i) + 1)
          item.product = {
            'id': menu.combo_uuid,
            'name': menu.name,
            'category': menu.base_category.short_name,
            'attributes': {},
            'variants': [],
            'value': {
              'displayGross': menu.item_price,
              'displayTax': '0'
            },
            'tax': {
              'type': '',
              'name': '',
              'size': ''
            },
            'purchaseMethod': 'main',
            'bundleItems': []
          }
          item.quantity = menu.quantity
          item.value = {
            'displayGross': menu.total,
            'displayTax': '0'
          }
          item.discount = {
            lines: [],
            'value': {
              'displayGross': '',
              'displayTax': '0'
            }
          }
          if (menu.coupon_code != null && !this.empty(menu.coupon_code)) {
            item.discount.lines.push(this.getCoupondetail(menu.coupon_code, vm.cart.coupon.data))
          }
          let bundleItems = []
          for (let c in menu.items) {
            for (let j in menu.items[c].group_items) {
              let layerInfo = vm.lodash.cloneDeep(menu.items[c].group_items[j].layer_info)
              let variantOb = {
                quantity: 1,
                'value': {
                  'displayGross': '0',
                  'displayTax': '0'
                }
              }
              let price = 0
              if (layerInfo.hasOwnProperty('first') && layerInfo.first != null) {
                price = layerInfo.first.price
                variantOb.id = layerInfo.first.first_id
                variantOb.name = menu.items[c].group_items[j].name + '-' + layerInfo.first.name
                variantOb.type = 'size'
              }
              if (layerInfo.hasOwnProperty('second') && layerInfo.second != null) {
                variantOb.id += '-' + layerInfo.second.second_id
                variantOb.name += '-' + layerInfo.second.name
                variantOb.type += '-crust'
                price += layerInfo.second.price
              }
              variantOb.value.displayGross = price
              bundleItems.push({
                productId: menu.items[c].group_items[j].mapping_single_uuid,
                category: menu.items[c].group_items[j].category || menu.items[c].group_items[j].base_category.sku,
                quantity: '1',
                name: menu.items[c].group_items[j].name,
                purchaseMethod: 'deal',
                variants: [variantOb],
                value: {
                  displayGross: menu.items[c].group_items[j].base_price || 0,
                  displayTax: 0
                }
              })
            }
          }
          item.product.bundleItems = bundleItems
        } else if (menu.product_type === vm.$const.CARD_ITEM_TYPE.SINGLE) {
          item.id = vm.cart.uuid + '-' + (parseFloat(i) + 1)
          item.product = {
            'id': '',
            'name': '',
            'category': menu.base_category.short_name,
            'attributes': {},
            'variants': [],
            'value': {
              'displayGross': menu.item_price,
              'displayTax': '0'
            },
            'tax': {
              'type': '',
              'name': '',
              'size': ''
            },
            'purchaseMethod': 'main',
            'bundleItems': []
          }
          item.quantity = menu.quantity
          item.value = {
            'displayGross': menu.total,
            'displayTax': '0'
          }
          item.discount = {
            lines: [],
            'value': {
              'displayGross': '',
              'displayTax': '0'
            }
          }
          if (menu.coupon_code != null && !this.empty(menu.coupon_code)) {
            item.discount.lines.push(this.getCoupondetail(menu.coupon_code, vm.cart.coupon.data))
          }
          for (let v in menu.items) {
            if (v === '0') {
              item.product.id = menu.items[v].product_uuid
              item.product.name = menu.items[v].name
            }
            if (typeof menu.items[v].layer_info.first !== 'undefined' && !vm.empty(menu.items[v].layer_info.first)) {
              let variant = {}
              let price = menu.items[v].layer_info.first.price
              variant.id = menu.items[v].layer_info.first.first_id
              variant.name = menu.items[v].name + '-' + menu.items[v].layer_info.first.name
              variant.type = 'size'
              if (typeof menu.items[v].layer_info.second !== 'undefined' && !vm.empty(menu.items[v].layer_info.second)) {
                variant.type += '-crust'
                variant.name += '-' + menu.items[v].layer_info.second.name
                price += menu.items[v].layer_info.second.price
                variant.id += '-' + menu.items[v].layer_info.second.second_id
              }
              variant.value = {
                displayGross: price,
                displayTax: 0
              }
              item.product.variants.push(variant)
            }
          }
        } else if (menu.product_type === vm.$const.CARD_ITEM_TYPE.HALF) {
          item.id = vm.cart.uuid + '-' + (parseFloat(i) + 1)
          item.product = {
            'id': vm.$const.HALF_HALF_UUID,
            'name': menu.name,
            'category': menu.base_category.sku,
            'attributes': {},
            'variants': [],
            'value': {
              'displayGross': menu.total,
              'displayTax': '0'
            },
            'tax': {
              'type': '',
              'name': '',
              'size': ''
            },
            'purchaseMethod': 'main',
            'bundleItems': []
          }
          if (menu.only_lunch) {
            item.product.id = vm.$const.HALF_HALF_LUNCH_UUID
          }
          item.quantity = menu.quantity
          item.value = {
            'displayGross': menu.total,
            'displayTax': '0'
          }
          item.discount = {
            lines: [],
            'value': {
              'displayGross': '',
              'displayTax': '0'
            }
          }
          let bundleItems = []
          for (let i in menu.items) {
            let _menu = vm.lodash.cloneDeep(menu.items[i])
            let layerInfo = vm.lodash.cloneDeep(_menu.layer_info)
            let variantOb = {
              quantity: 1,
              'value': {
                'displayGross': 0,
                'displayTax': 0
              }
            }
            let price = _menu.price || 0
            if (layerInfo.hasOwnProperty('first') && layerInfo.first != null) {
              variantOb.id = layerInfo.first.uuid
              price += layerInfo.first.price
              variantOb.name = _menu.name + '-' + layerInfo.first.name
              variantOb.type = 'size'
              variantOb.value.displayGross += layerInfo.first.price
            }
            if (layerInfo.hasOwnProperty('second') && layerInfo.second != null) {
              variantOb.name += '-' + layerInfo.second.name
              variantOb.id += '-' + layerInfo.second.uuid
              variantOb.type += '-crust'
              price += layerInfo.second.price
              variantOb.value.displayGross += layerInfo.second.price
            }
            bundleItems.push({
              productId: _menu.product_uuid,
              category: _menu.category || '', // not yet from api
              quantity: '1',
              name: _menu.name,
              purchaseMethod: 'half-half',
              variant: variantOb,
              value: {
                displayGross: price,
                displayTax: 0
              }
            })
          }
          item.product.bundleItems = bundleItems
          if (menu.coupon_code != null && !this.empty(menu.coupon_code)) {
            item.discount.lines.push(this.getCoupondetail(menu.coupon_code, vm.cart.coupon.data))
          }
        }
        cart.lines.push(item)
      }
      cart.discount = {
        lines: [],
        value: {
          'displayGross': '0.00',
          'displayTax': '0.00'
        }
      }
      if (vm.cart.coupon) {
        cart.discount.value.displayGross = vm.cart.coupon.total_discount

        for (let c in vm.cart.coupon.data) {
          if (vm.cart.coupon.data[c].discount_type != vm.$const.COUPON_TYPE_ITEM) {
            cart.discount.lines.push({
              id: vm.cart.coupon.data[c].code,
              name: vm.cart.coupon.data[c].name,
              code: vm.cart.coupon.data[c].code,
              type: vm.cart.coupon.data[c].discount_type_name,
              size: vm.cart.coupon.data[c].discount_percent_unit || 0,
              value: {
                'displayGross': vm.cart.coupon.data[c].discount_value,
                'displayTax': '0.00'
              }
            })
          }
        }
      }
      return cart
    },
    generateCartBasic (vm, includeProducts, includeDiscount) {
      vm.$cart.init(vm)
      let cartData = vm.lodash.cloneDeep(vm.cart)
      let cart = {
        'id': cartData.uuid,
        'currency': vm.$const.CURRENCY_CODE,
        'shipping': {
          'value': {
            'displayGross': cartData.delivery_fee || 0,
            'displayTax': '0.00'
          },
          'discount': {},
          'type': vm.$disposition.getByKey(vm, 'orderType', '').toLowerCase(),
          'storeId': vm.$disposition.getByKey(vm, 'outletCode', ''),
        },
        'value': {
          'displayGross': cartData.total || 0,
          'displayTax': cartData.tax_total || 0
        }
      }
      if (includeProducts) {
        cart.lines = []
        for (let i in cartData.data) {
          let menu = vm.lodash.cloneDeep(cartData.data[i])
          let item = {
            id: cartData.uuid + '-' + (parseFloat(i) + 1),
            product: {},
            quantity: menu.quantity,
            value: {
              'displayGross': menu.total,
              'displayTax': 0
            }
          }
          if (menu.product_type === vm.$const.CARD_ITEM_TYPE.COMBO) {
            let bundleItems = []
            item.product.id = menu.combo_uuid
            item.product.name = menu.name
            item.product.category = menu.base_category ? menu.base_category.sku : ''
            item.product.attributes = {}
            item.product.purchaseMethod = 'main'
            item.product.bundleItems = []
            item.product.tax = {
              'type': '',
              'name': '',
              'size': ''
            }
            item.product.value = {
              displayGross: menu.item_price,
              displayTax: 0
            }
            item.product.variants = []
            for (let c in menu.items) {
              for (let j in menu.items[c].group_items) {
                let layerInfo = vm.lodash.cloneDeep(menu.items[c].group_items[j].layer_info)
                let variantOb = {
                  quantity: 1,
                  'value': {
                    'displayGross': 0,
                    'displayTax': 0
                  }
                }
                if (layerInfo.hasOwnProperty('first') && layerInfo.first != null) {
                  variantOb.id = layerInfo.first.first_id
                  variantOb.name = menu.items[c].group_items[j].name + '-' + layerInfo.first.name
                  variantOb.type = 'size'
                  variantOb.value.displayGross += layerInfo.first.price
                }
                if (layerInfo.hasOwnProperty('second') && layerInfo.second != null) {
                  variantOb.name += '-' + layerInfo.second.name
                  variantOb.id += '-' + layerInfo.second.second_id
                  variantOb.type += '-crust'
                  variantOb.value.displayGross += layerInfo.second.price
                }
                bundleItems.push({
                  productId: menu.items[c].group_items[j].mapping_single_uuid,
                  category: menu.items[c].group_items[j].category || '', // not yet from api
                  quantity: '1',
                  name: menu.items[c].group_items[j].name,
                  purchaseMethod: 'deal',
                  variant: variantOb,
                  value: {
                    displayGross: menu.items[c].group_items[j].base_price || 0,
                    displayTax: 0
                  }
                })
              }
            }
            item.product.bundleItems = bundleItems
          } else if (menu.product_type === vm.$const.CARD_ITEM_TYPE.SINGLE) {
            item.product.id = ''
            item.product.name = ''
            item.product.category = menu.base_category ? menu.base_category.sku : ''
            item.product.attributes = {}
            item.product.purchaseMethod = 'main'
            item.product.bundleItems = []
            item.product.tax = {
              'type': '',
              'name': '',
              'size': ''
            }
            item.product.value = {
              displayGross: menu.item_price,
              displayTax: 0
            }
            item.product.variants = []
            let datamenu = vm.lodash.cloneDeep(menu.items)
            for (let v in datamenu) {
              if (v === '0') {
                item.product.id = datamenu[v].product_uuid
                item.product.name = datamenu[v].name
              }
              let layerInfo = vm.lodash.cloneDeep(datamenu[v].layer_info)

              if (typeof layerInfo.first !== 'undefined' && !vm.empty(layerInfo.first)) {
                let variant = {}
                let price = layerInfo.first.price
                variant.id = layerInfo.first.first_id
                variant.name = datamenu[v].name + '-' + layerInfo.first.name
                variant.type = 'size'
                if (typeof layerInfo.second !== 'undefined' && !vm.empty(layerInfo.second)) {
                  variant.type += '-crust'
                  variant.name += '-' + layerInfo.second.name
                  price += layerInfo.second.price
                  variant.id += '-' + layerInfo.second.second_id
                }
                variant.value = {
                  displayGross: price,
                  displayTax: 0
                }
                item.product.variants.push(variant)
              }
            }
          } else if (menu.product_type === vm.$const.CARD_ITEM_TYPE.HALF) {
            item.product.id = ''
            item.product.name = ''
            item.product.category = ''
            item.product.attributes = {}
            item.product.purchaseMethod = 'main'
            item.product.bundleItems = []
            item.product.tax = {
              'type': '',
              'name': '',
              'size': ''
            }
            item.product.value = {
              displayGross: menu.total,
              displayTax: 0
            }
            item.product.variants = []
            item.product.id = vm.$const.HALF_HALF_UUID
            if (menu.only_lunch) {
              item.product.id = vm.$const.HALF_HALF_LUNCH_UUID
            }
            item.product.name = menu.name
            let bundleItems = []
            let datamenu = vm.lodash.cloneDeep(menu.items)
            for (let i in datamenu) {
              let _menu = vm.lodash.cloneDeep(datamenu[i])
              let layerInfo = vm.lodash.cloneDeep(_menu.layer_info)
              let variantOb = {
                quantity: 1,
                'value': {
                  'displayGross': 0,
                  'displayTax': 0
                }
              }
              let price = _menu.price || 0
              if (layerInfo.hasOwnProperty('first') && layerInfo.first != null) {
                variantOb.id = layerInfo.first.uuid
                price += layerInfo.first.price
                variantOb.name = _menu.name + '-' + layerInfo.first.name
                variantOb.type = 'size'
                variantOb.value.displayGross += layerInfo.first.price
              }
              if (layerInfo.hasOwnProperty('second') && layerInfo.second != null) {
                variantOb.name += '-' + layerInfo.second.name
                variantOb.id += '-' + layerInfo.second.uuid
                variantOb.type += '-crust'
                price += layerInfo.second.price
                variantOb.value.displayGross += layerInfo.second.price
              }
              bundleItems.push({
                productId: _menu.product_uuid,
                category: _menu.category || '', // not yet from api
                quantity: '1',
                name: _menu.name,
                purchaseMethod: 'half-half',
                variant: variantOb,
                value: {
                  displayGross: price,
                  displayTax: 0
                }
              })
            }
            item.product.bundleItems = bundleItems
          }
          cart.lines.push(item)
        }
      }
      if (includeDiscount) {
        cart.discount = {
          lines: [],
          value: {
            'displayGross': '0.00',
            'displayTax': '0.00'
          }
        }
        if (cartData.coupon) {
          cart.discount.value.displayGross = cartData.coupon.total_discount

          for (let c in cartData.coupon.data) {
            if (cartData.coupon.data[c].discount_type != vm.$const.COUPON_TYPE_ITEM) {
              cart.discount.lines.push({
                id: cartData.coupon.data[c].code,
                name: cartData.coupon.data[c].name,
                code: cartData.coupon.data[c].code,
                type: 'fixed',
                size: cartData.coupon.data[c].discount_value,
                value: {
                  'displayGross': cartData.coupon.data[c].discount_value,
                  'displayTax': '0.00'
                }
              })
            }
          }
        }
      }
      return cart
    },
    eventTrackingLoadCart (vm) {
      //  console.info('+++++++++++++eventTrackingLoadCart data ++++++++++++++')
      let layer = {
        'event': 'cart.load.auto.dataLayer.load',
        'cart': this.generateCartBasic(vm, true)
      }
      //  console.info('+++++++++++++eventTrackingLoadCart data ++++++++++++++')
      //  console.info(layer)
      //  console.info('+++++++++++++eventTrackingLoadCart end ++++++++++++++')
      dataLayer.push(layer)
    },
    eventTrackingFormLoad (form) {
      //  console.info('+++++++++++++eventTrackingFormLoad ++++++++++++++')
      let data = {
        'event': 'form.load.auto.dataLayer.load',
        'form': {
          'id': form.id,
          'name': form.name,
          'category': form.category,
          'target': form.url
        }
      }
      //  console.info('+++++++++++++eventTrackingFormLoad data ++++++++++++++')
      //  console.info(data)
      //  console.info('+++++++++++++eventTrackingFormLoad end ++++++++++++++')
      dataLayer.push(data)
    },
    eventTrackingFormSubmit (form) {
      //  console.info('+++++++++++++eventTrackingFormSubmit ++++++++++++++')
      let data = {
        'event': 'form.interact.manual.submit.formSubmit',
        'form': {
          'id': form.id
        }
      }
      //  console.info('+++++++++++++eventTrackingFormSubmit data ++++++++++++++')
      //  console.info(data)
      //  console.info('+++++++++++++eventTrackingFormSubmit end ++++++++++++++')
      dataLayer.push(data)
    },
    eventTrackingFormSuccess (form, method) {
      //  console.info('+++++++++++++eventTrackingFormSuccess ++++++++++++++')
      let data = {
        'event': 'form.load.auto.success.formSuccess',
        'form': {
          'id': form.id
        }
      }
      if (method) {
        data.method = method
      }
      //  console.info('+++++++++++++eventTrackingFormSuccess data ++++++++++++++')
      //  console.info(data)
      //  console.info('+++++++++++++eventTrackingFormSuccess end ++++++++++++++')
      dataLayer.push(data)
    },
    eventTrackingShippingInfo(vm) {
      try {
        // console.info('+++++++++++++eventTrackingShippingInfo ++++++++++++++')
        let shipping = 'delivery';
        const orderType = vm.$disposition.getByKey(vm, 'orderType', 'D')
        if (orderType === vm.$const.CARRYOUT) {
          shipping = 'carryout';
        }
        let data = {
          'event': 'cart.interact.auto.dataLayer.shippingInfo',
          'shipping_tier': shipping
        }
        // console.info('+++++++++++++eventTrackingShippingInfo data ++++++++++++++')
        // console.info(data)
        // console.info('+++++++++++++eventTrackingShippingInfo end ++++++++++++++')
        dataLayer.push(data)
      } catch (error) {
        console.error(error);
        // console.error('+++++++++++++eventTrackingShippingInfo error ++++++++++++++')
      }
    },
    eventTrackingPaymentMethodSelected(paymentMethod) {
      try {
        // console.info('+++++++++++++eventTrackingPaymentMethodSelected ++++++++++++++')
        let data = {
          'event': 'cart.interact.manual.submit.paymentMethodSelected',
          'payment_type': paymentMethod
        }
        // console.info('+++++++++++++eventTrackingPaymentMethodSelected data ++++++++++++++')
        // console.info(data)
        // console.info('+++++++++++++eventTrackingPaymentMethodSelected end ++++++++++++++')
        dataLayer.push(data)
      } catch (error) {
        console.error(error);
        // console.error('+++++++++++++eventTrackingPaymentMethodSelected error ++++++++++++++')
      }
    },
    eventTrackingAutoLocalised() {
      try {
        // console.info('+++++++++++++eventTrackingAutoLocalised ++++++++++++++')
        let data = {
          'event': 'user.interact.auto.success.auto.localisation'
        }
        // console.info('+++++++++++++eventTrackingAutoLocalised data ++++++++++++++')
        // console.info(data)
        // console.info('+++++++++++++eventTrackingAutoLocalised end ++++++++++++++')
        dataLayer.push(data)
      } catch (error) {
        console.error(error);
        // console.error('+++++++++++++eventTrackingAutoLocalised error ++++++++++++++')
      }
    },
    eventTrackingFailureLocalised() {
      try {
        // console.info('+++++++++++++eventTrackingFailureLocalised ++++++++++++++')
        let data = {
          'event': 'user.interact.auto.failure.localisation'
        }
        // console.info('+++++++++++++eventTrackingFailureLocalised data ++++++++++++++')
        // console.info(data)
        // console.info('+++++++++++++eventTrackingFailureLocalised end ++++++++++++++')
        dataLayer.push(data)
      } catch (error) {
        console.error(error);
        // console.error('+++++++++++++eventTrackingFailureLocalised error ++++++++++++++')
      }
    },
    eventTrackingFormError (form) {
      //  console.info('+++++++++++++eventTrackingFormError ++++++++++++++')
      let data = {
        'event': 'form.load.auto.error.formError',
        'form': {
          'id': form.id
        },
        'error': {
          'code': form.error.code,
          'type': form.error.type,
          'error': form.error.error
        }
      }
      //  console.info('+++++++++++++eventTrackingFormError data ++++++++++++++')
      //  console.info(data)
      //  console.info('+++++++++++++eventTrackingFormError end ++++++++++++++')
      dataLayer.push(data)
    },
    eventTrackingMoudle (data) {
      let links = []
      for (let i in data) {
        links.push(data[i])
      }
      let layer = {
        assets: {
          links: links
        }
      }
      //  console.info('+++++++++++++eventTrackingMoudle data ++++++++++++++')
      //  console.info(layer)
      //  console.info('+++++++++++++eventTrackingMoudle end ++++++++++++++')
      dataLayer.push(layer)
    },
    eventTrackingModule (data) {
      //  console.info('+++++++++++++eventTrackingModule data ++++++++++++++')
      //  console.info(data)
      //  console.info('+++++++++++++eventTrackingModule end ++++++++++++++')
      dataLayer.push(data)
    },
    eventTrackingLocalisedStart () {
      try {
        console.log('+++++++++++++ eventTrackingLocalisedStart start +++++++++++++')
        const layer = {
          event: 'user.interact.manual.localisationStart'
        }
        dataLayer.push(layer)
      } catch (error) {
        console.log('+++++++++++++ eventTrackingLocalisedStart error +++++++++++++')
        console.error(error)
      }
    },
    eventTrackingFindMyLocation () {
      try {
        console.log('+++++++++++++ eventTrackingFindMyLocation start +++++++++++++')
        const layer = {
          event: 'user.interact.manual.findMyLocation'
        }
        dataLayer.push(layer)
      } catch (error) {
        console.log('+++++++++++++ eventTrackingFindMyLocation error +++++++++++++')
        console.error(error)
      }
    },
    eventTrackingLoginSuccess (vm, method = 'email') {
      //  console.info('+++++++++++++eventTrackingLoginSuccess ++++++++++++++')
      let layer = {
        'event': 'user.interact.auto.success.loginSuccess',
        'method': method,
        'user': this.getUserDatalayer(vm)
      }
      //  console.info(layer)
      //  console.info('+++++++++++++eventTrackingLoginSuccess end ++++++++++++++')
      dataLayer.push(layer)
    },
    eventTrackingLoginStart(method = 'email') {
      // console.info('+++++++++++++eventTrackingLoginStart ++++++++++++++')
      let layer = {
        'event': 'user.interact.manual.click.loginStart',
        'method': method
      }
      // console.info(layer)
      // console.info('+++++++++++++eventTrackingLoginStart end ++++++++++++++')
      dataLayer.push(layer)
    },
    eventTrackingLoginSubmit(method = 'email') {
      // console.info('+++++++++++++eventTrackingLoginSubmit ++++++++++++++')
      let layer = {
        'event': 'user.interact.manual.submit.loginSubmit',
        'method': method
      }
      // console.info(layer)
      // console.info('+++++++++++++eventTrackingLoginSubmit end ++++++++++++++')
      dataLayer.push(layer)
    },
    eventTrackingRegisterStart(method = 'email') {
      // console.info('+++++++++++++eventTrackingRegisterStart ++++++++++++++')
      let layer = {
        'event': 'user.interact.manual.click.regStart',
        'method': method
      }
      // console.info(layer)
      // console.info('+++++++++++++eventTrackingRegisterStart end ++++++++++++++')
      dataLayer.push(layer)
    },
    eventTrackingRegisterSubmit(method = 'email') {
      // console.info('+++++++++++++eventTrackingRegisterSubmit ++++++++++++++')
      let layer = {
        'event': 'user.interact.manual.submit.regSubmit',
        'method': method
      }
      // console.info(layer)
      // console.info('+++++++++++++eventTrackingRegisterSubmit end ++++++++++++++')
      dataLayer.push(layer)
    },
    eventTrackingModuleLoadDeal (vm, group) {
      //  console.info('+++++++++++++eventTrackingModuleLoadDeal++++++++++++++')
      let categoryUuid = vm.$session.get('categoryUuid') || vm.$store.getters.category_uuid
      let category = {}
      let categories = vm.$session.get('listCategory')
      if (categories.length > 0) {
        for (let i in categories) {
          if (categories[i].uuid === categoryUuid) {
            category = categories[i]
            break
          }
        }
        if (this.empty(category)) {
          category = categories[0]
        }
      }
      let items = []
      let products = []
      for (let i in group.items) {
        items.push({
          'id': group.items[i].single_uuid,
          'type': 'product',
          'position': (parseFloat(i) + 1)
        })
        let variants = []
        let item = group.items[i]
        if (item.first_layers && item.first_layers.length > 0) {
          for (let f in item.first_layers) {
            let price = item.first_layers[f].price
            if (typeof item.first_layers[f].second_layers !== 'undefined' && !vm.empty(item.first_layers[f].second_layers)) {
              for (let s in item.first_layers[f].second_layers) {
                price = item.first_layers[f].price + item.first_layers[f].second_layers[s].price
                variants.push({
                  id: item.first_layers[f].first_id + '-' + item.first_layers[f].second_layers[s].second_id,
                  name: item.name + '-' + item.first_layers[f].name + '-' + item.first_layers[f].second_layers[s].name,
                  type: 'size-crust',
                  quantity: 1,
                  value: {
                    displayGross: price,
                    displayTax: 0
                  }
                })
              }
            } else {
              variants.push({
                id: item.first_layers[f].uuid,
                name: item.name + '-' + item.first_layers[f].name,
                type: 'size',
                quantity: 1,
                value: {
                  displayGross: price,
                  displayTax: 0
                }
              })
            }
          }
        }
        products.push({
          'id': group.items[i].single_uuid,
          'name': group.items[i].name,
          'category': group.items[i].category.name_seo || '',
          'variants': variants,
          'value': {
            displayGross: group.items[i].price || 0,
            displayTax: 0
          },
          'tax': {
            'type': '',
            'name': '',
            'size': ''
          },
          'purchaseMethod': 'deal',
          'bundleItems': []
        })
      }
      let data = {
        'event': 'module.load.manual.dataLayer.load',
        'modules': [
          {
            'id': group.uuid || '',
            'name': group.name || '',
            'type': 'merchandising',
            'placement': 'item-selection-box',
            'items': items
          }
        ],
        'products': products
      }
      //  console.info('+++++++++++++eventTrackingModuleLoadDeal data ++++++++++++++')
      //  console.info(data)
      //  console.info('+++++++++++++eventTrackingModuleLoadDeal end ++++++++++++++')
      dataLayer.push(data)
    },
    getProductByUUID (uuid, callback) {
      const availableSingle = this.$store.state.selectedCategoryItems
        ? this.lodash
          .flatten(
            this.$store.state.selectedCategoryItems.map(type => type.singles)
          )
          .find(single => single.uuid === uuid)
        : undefined
      if (availableSingle) {
        callback(availableSingle, null)
      } else {
        let orderTime = this.convertDateTimeFromTimezoneToUTC(this.$disposition.getByKey(this, 'timezone', ''),
                                                              this.$disposition.getByKey(this, 'collectionTimeValue', ''))
        if (!orderTime) {
          orderTime = moment().utc().format('YYYY-MM-DD HH:mm:ss')
        }
        let data = {
          tier: this.$disposition.getByKey(this, 'tier', '1'),
          order_type: this.$disposition.getByKey(this, 'orderType', 'D').charAt(0),
          order_time: orderTime,
          outlet_code: this.getOutletCode(this)
        }

        this.axios.getMenuDetails(uuid, data, function (response) {
          callback(response.data.data.item, null)
        }, function (error) {
          callback(null, error)
        })
      }
    },
    redirectToMenuPage (slug = null, subSlug = null, uuid = null, isReplace = false) {
      this.$root.$emit(this.$const.EVENT_BUS.REDIRECT_TO_MENU_PAGE, slug, subSlug, uuid, isReplace)
    },
    redirectToItemPage (slug = null, subSlug = null, uuid = null, isReplace = false) {
      this.$root.$emit(this.$const.EVENT_BUS.REDIRECT_TO_ITEM_PAGE, slug, subSlug, uuid, isReplace)
    },
    redirectToPage (array) {
      let categoryList = this.$session.get('listCategory')
      for (let i in categoryList) {
        if (this.lodash.includes(array, categoryList[i].slug)) {
          this.$router.push('/order/' + categoryList[i].slug)
          return
        }
      }
    },
    callEventBus (eventName, data, callback) {
      this.$bus.$emit(eventName, data, callback)
    },
    callEventListener (eventName, data, callback) {
      this.$root.$emit(eventName, data, callback)
    },
    back () {
      this.$router.back()
    },
    redirectToUrl (url, isRedirect = false, newTab = false, isReplace = false) {
      if (isRedirect) {
        if (newTab) {
          window.open(url, '_blank')
        } else {
          window.location.href = url
        }
      } else {
        if (isReplace) {
          this.$router.replace(url)
        } else {
          this.$router.push(url)
        }
      }

      return true
    },
    redirectTo (name, params) {
      this.$router.push({'name': name, 'params': params})
    },
    getCouponImage (item, image = null) {
      if (!image && (!item.image || this.lodash.isEmpty(item.image))) {
        let lang = this.$i18n ? this.$i18n.locale || this.$const.LANG_ES : this.$const.LANG_ES
        if (!this.empty(lang)) {
          lang = lang.toUpperCase()
        } else {
          lang = this.$const.LANG_ES.toUpperCase()
        }
        if (this.lodash.includes(this.$const.COUPON_TYPES.DISCOUNT, item.coupon_type_id)) {
          return this.$const.COUPON_IMAGES.DISCOUNT[lang]
        } else if (this.lodash.includes(this.$const.COUPON_TYPES.FREE_GIFT, item.coupon_type_id)) {
          return this.$const.COUPON_IMAGES.FREE_GIFT[lang]
        } else {
          return this.$const.COUPON_IMAGES.PROMOTION[lang]
        }
      } else {
        return image || item.image.desktop
      }
    },
    setActiveToppingList (extraToppingList, defaultToppingList) {
      // CHECK PER CATEGORY
      for (let ext in extraToppingList) {
        let count = 0
        for (let def in defaultToppingList) {
          if (extraToppingList[ext].id === defaultToppingList[def].id && !defaultToppingList[def].default) {
            count++
          }

          extraToppingList[ext].isDisable = false
          if (!extraToppingList[ext].default && count >= this.$const.TOPPING.MAX_PER_TOPPING) {
            extraToppingList[ext].isDisable = true
            break
          }
        }
      }

      if (this.$const.TOPPING.MAX_EXTRA) {
        let defaultList = defaultToppingList.filter(topping => {
          return topping.type === this.$const.TOPPING.TYPE_EXTRA
        })

        if (defaultList && defaultList.length >= this.$const.TOPPING.MAX_EXTRA) {
          for (let ext in extraToppingList) {
            if (extraToppingList[ext].type === this.$const.TOPPING.TYPE_EXTRA) {
              extraToppingList[ext].isDisable = true
            }
          }
        }
      }
    },
    convertToKanaName (text, callback) {
      this.axios.apiCustomer.convertKana(text, (response) => {
        callback(null, response.data.data.result)
      }, function (error) {
        callback(true)
      })
    },
    getMenuRequest (categoryId = null, subCategoryId = null) {
      let orderTime = this.convertDateTimeFromTimezoneToUTC(this.$disposition.getByKey(this, 'timezone', ''),
        this.$disposition.getByKey(this, 'collectionTimeValue', ''))
      if (!orderTime) {
        orderTime = moment().utc().format('YYYY-MM-DD HH:mm:ss')
      }

      let data = {
        'order_time': orderTime,
        'tier': this.$disposition.getByKey(this, 'tier', '1'),
        'order_type': this.$disposition.getByKey(this, 'orderType', 'D').charAt(0)
      }

      let outletCode = this.$disposition.getByKey(this, 'outletCode', null)
      if (outletCode) {
        data = {
          'order_time': orderTime,
          'outlet_code': this.$disposition.getByKey(this, 'outletCode', null),
          'tier': this.$disposition.getByKey(this, 'tier', '1'),
          'order_type': this.$disposition.getByKey(this, 'orderType', 'D').charAt(0)
        }
      }

      if (categoryId) {
        data.category_id = categoryId
      }

      if (subCategoryId) {
        data.sub_category_id = subCategoryId
      }

      return data
    },
    getWaitingTime (vm) {
      let number = 'null'
      let type = vm.$disposition.getByKey(this, 'orderType', 'D').charAt(0)
      let quoctime = vm.$disposition.getByKey(this, 'quoteTime', {})
      if (quoctime && quoctime.hasOwnProperty(type)) {
        number = quoctime[type]
      }
      return number
    },
    setOutletById (vm, outletId, orderType, callback) {
      main.callEventBus(main.$const.EVENT_BUS.LOADING, true)
      let data = {
        order_type: orderType.charAt(0),
        order_time: this.convertDateTimeFromTimezoneToUTC(this.$disposition.getByKey(this, 'timezone', ''),
          this.$disposition.getByKey(this, 'collectionTimeValue', '')),
        advance_order: this.$disposition.getByKey(this, 'advanceOrder', 0) || 0,
        outlet_id: outletId
      }

      this.axios.apiOutlet.checkOutletAvailable(data,
        function (response) {
          response = response.data
          if (response.status) {
            let data = vm.$disposition.get(vm)
            response = response.data.item
            // set data
            data.lat = response.lat
            data.long = response.long
            data.postalCode = response.zip
            // data.locationName = outletData.kanji_address
            // data.outletAddress = outletData.kanji_address
            data.orderType = orderType
            data.outletId = response.id
            data.outletName = response.name
            data.outletCode = response.code
            // data.addressCode = outletData.address_code
            data.advanceOrder = vm.$disposition.getByKey(vm, 'advanceOrder', 0) || 0
            data.tier = response.tier
            data.minCart = response.min_cart
            data.houseNo = ''
            data.landmark = ''
            data.quoteTime = response.quote_time
            vm.disposition = data

            let cart = vm.$cart.get(vm)

            if (cart.uuid) {
              vm.$modal.show('dialog', {
                text: vm.$t('confirm.remove_cart'),
                buttons: [
                  {
                    title: vm.$t('button_yes'),
                    handler: () => {
                      vm.proceessDispostion(vm, data, function () {
                        vm.$modal.hide('dialog')
                        vm.redirectToPage(vm.$const.SLUG_LIST.DEAL)
                      })
                    }
                  },
                  {
                    title: vm.$t('button_close')
                  }
                ]
              })
            } else {
              vm.proceessDispostion(vm, data, function () {
                vm.redirectToMenuPage()
              })
            }
          } else if (response.message && response.message != '') {
            vm.$notify({
              type: 'warn',
              title: response.message
            })
          }

          main.callEventBus(main.$const.EVENT_BUS.LOADING, false)
        },
        function (error) {
          error = error.error
          vm.eventTrackingLocalisationError({id: 'error-zip-code', error: 'invalid-zip-code'})
          main.callEventBus(main.$const.EVENT_BUS.LOADING, false)

          let message = vm.$t('modal.advance_order_message')
          vm.eventTrackingFailureLocalised()
          if (error.code === vm.$const.LOCALIZATION_CODE.UNAVALIABLE) {
            message = vm.$t('message_find_outlet_empty')
            vm.$modal.show('dialog', {
              text: message,
              buttons: [
                {
                  title: vm.$t('button_close')
                }
              ]
            })
          } else if (error.code === vm.$const.LOCALIZATION_CODE.NOTOPEN) {
            message = vm.$t('localization.notopen')
            vm.$modal.show('dialog', {
              text: message,
              buttons: [
                {
                  title: vm.$t('button_close')
                }
              ]
            })
          } else {
            vm.$modal.show(ASAPPopup, {outletId: outletId, orderType: orderType, hideASAP: 'true', message: message})

            // vm.callEventBus(vm.$const.EVENT_BUS.SHOW_ASAP, true)
            /* vm.$modal.show(ASAPPopup, {
              outletId: outletId,
              orderType: orderType,
              outletDataZip: outletData,
              hideASAP: 'true',
              message: message
            }) */
          }
        }
      )
    },
    getCurrentOrderType () {
      return this.$disposition.getByKey(this, 'orderType', '').toUpperCase()
    },
    proceessDispostion (vm, outlet, callback) {
      if (outlet.addressCode) {
        let data = {address_code: outlet.addressCode}
        this.axios.apiOutlet.getAddressByAddressCode(data,
          function (response) {
            response = response.data.data.items
            if (response.length > 0) {
              outlet.prefectureName = response[0].level1
              outlet.cityName = response[0].level2
              outlet.villeageName = response[0].level3
              outlet.st = response[0].level4
            }

            vm.$disposition.set(vm, outlet)
            vm.$session.remove('cart')
            vm.$root.$emit('cartUpdate')

            callback()
          },
          function (error) {
          }
        )
      } else {
        vm.$disposition.set(vm, outlet)
        vm.$session.remove('cart')
        vm.$root.$emit('cartUpdate')

        callback()
      }
    },
    setHtmlClass (tagName, className, isRemove = false) {
      const element = document.getElementsByTagName(tagName)[0]
      if (className) {
        if (isRemove) {
          element.className = body.className.replace(`/\b${className}\b/`, '')
        } else {
          element.classList.add(className)
        }
      }
    },
    setHtmlId (tagName, id) {
      const element = document.getElementsByTagName(tagName)[0]
      element.id = id
    },
    getTotalCartItem (cart) {
      let quantity = 0
      if (cart.data) {
        for (let i in cart.data) {
          quantity += cart.data[i].quantity
        }
      }
      return quantity
    },
    parseQueryString (data) {
      return Object.keys(data).map(function (key) {
        return key + '=' + data[key]
      }).join('&')
    },
    formatDate (date, formatInput, formatOutput) {
      return moment(date, formatInput).format(formatOutput)
    },
    getItemQuantity (oldValue, newValue) {
      if (oldValue === newValue) {
        return newValue
      }
      if (newValue > oldValue) {
        return newValue - oldValue
      }
      if (newValue < oldValue) {
        return oldValue - newValue
      }
    },
    getStringIgnoreHTTMLTag (value) {
      return value.replace(/<(?:.|\n)*?>/gm, '')
    },
    generateTrackingInfo (item, quantity) {
      let product = {
        items: [],
        item,
        name: item.name,
        quantity: this.getItemQuantity(item.quantity, quantity),
        price: item.item_price * quantity,
        category: item.base_category.short_name
      }
      switch (item.product_type) {
        case this.$const.CARD_ITEM_TYPE.SINGLE:
          product.id = item.items[0].product_uuid
          product.first = item.items[0].layer_info.first
          product.second = item.items[0].layer_info.second
          break
        case this.$const.CARD_ITEM_TYPE.COMBO:
          product.id = item.combo_uuid
          for (let i in item.items) {
            let obj = item.items[i].group_items[0]
            product.items.push({
              item: obj,
              first: obj.layer_info.first,
              second: obj.layer_info.second
            })
          }
          break
      }
      return product
    }
  }
}
